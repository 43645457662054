<!--
 * @Description: 
 * @Author: wangying
 * @Date: 2021-09-26 16:57:54
-->
<template>
  <div class="about">
    <p class="font_style Impact">About GSC Action</p>
    <p class="font_style_1 Impact" v-if="$store.state.language === 'cn'">关于绿链行动</p>
    <p class="font_style_1 mr"> </p>
    <p class="font_style_2"><!-- 2016年6月5日世界环境日，由阿拉善SEE、中城联盟、全联房地产商会、万科企业股份有限公司、朗诗控股集团这5家机构共同发起“中国房地产行业绿色供应链行动”。 --></p>
    <p class="font_style_2 font_w" v-if="$store.state.language === 'cn'">
      绿链行动以房地产企业及其上游供应商为主体、施行绿色采购。由第三方独立机构提供技术支持，推选环境表现良好的供应商进入推荐采购名单。房地产企业将根据制定的一系列绿色采购方案进行采购。目前房地产行业绿色供应链行动已有100多家房地产企业加入，共推出19个品类“白名单”评审规则，通过环境合规白名单查询平台收录超100万家白名单企业，19个品类“绿名单”导则，“绿名单”供应商企业超80家。
    </p>
    <p class="font_style_2 font_w" v-else>
      Green supply chain action (GSC action) mainly focuses on real estate enterprises and their upstream suppliers and implements green procurement. Independent third-party agencies provide technical support and select suppliers with good environmental performance to enter the recommended procurement list. Real estate enterprises will purchase according to a series of green procurement plans. At present, GSC action has attracted more than 100 real estate enterprises to join in, and has launched a total of 19 "white list" category guidelines. Also, GSC action has included more than 1 million white list enterprises through the environmental compliance whitelist query platform, launched 19 "green list" category guidelines, and attracted over 80 "green list" enterprises.
    </p>
    <div class="flex_1">
      <div class="pos_rit pointer" @click="addck(1)">
        <div class="font_style_fx shadow">100<span v-if="$store.state.language !== 'cn'">+</span></div>
        <div class="font_pos green"  v-if="$store.state.language === 'cn'">多家</div>
        <div class="font_pos green" v-else></div>
        <div class="font_pos_1 shadow2"  v-if="$store.state.language === 'cn'">房地产企业</div>
        <div class="font_pos_1 break shadow2" v-else>real estate enterprises</div>
      </div>
      <div class="pos_rit pointer" @click="addck(2)">
        <div class="font_style_fx shadow">19</div>
        <div class="font_pos font_pospl green" v-if="$store.state.language === 'cn'">个</div>
        <div class="font_pos_1 break shadow2"  v-if="$store.state.language === 'cn'">绿名单品类</div>
        <div class="font_pos_1 break shadow2" v-else>green list categories</div>
      </div>
      <div class="pos_rit pointer" @click="addck(3)">
        <div class="font_style_fx bai shadow">19</div>
        <div class="font_pos font_pospl green" v-if="$store.state.language === 'cn'">个</div>
        <div class="font_pos_1 break shadow2" v-if="$store.state.language === 'cn'">白名单品类</div>
        <div class="font_pos_1 break shadow2" v-else>white list categories</div>
      </div>
      <div class="pos_rit pointer" @click="addck(4)">
        <div class="font_style_fx bai shadow">80+</div>
        <div class="font_pos bai green" v-if="$store.state.language === 'cn'">家</div>
        <div class="font_pos_1 shadow2" v-if="$store.state.language === 'cn'">绿名单企业</div>
        <div class="font_pos_1 break shadow2" v-else>green list enterprises</div>
      </div>
      <div class="pos_rit pointer" @click="addck(5)">
        <div class="font_style_fx bai shadow" v-if="$store.state.language === 'cn'">超100万</div>
        <div class="font_style_fx bai shadow" v-else>1 million+</div>
        <div class="font_pos bai green" v-if="$store.state.language === 'cn'">家</div>
        <div class="font_pos_1 shadow2" v-if="$store.state.language === 'cn'">白名单企业</div>
        <div class="font_pos_1 shadow2" v-else>white list enterprises</div>
      </div>

    </div>
    <div class="pos_rit_bg ">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods: {
    addck(val) {
      switch (val) {
        case 1:
          this.$router.push({ name: 'MemberDirectory' })
          break;
        case 2:
          // this.$router.push({name: 'MemberDirectory'})
          break;
        case 3:
          this.$router.push({ name: 'SupplierSWhite' })
          break;
        case 4:
          this.$router.push({ name: 'GreenList' })
          break;
        case 5:
          // this.$router.push({name: 'GreenList'})
          break;
      }
    }
  }
}
</script>
<style lang="less" scoped>
.about {
  position: relative;
  height: 100%;
}

.font_style {
  color: #5a6f18;
  font-size: 50px;
  font-weight: 700;
  position: relative;
  top: 8%;
}

.font_style_1 {
  color: #92ab26;
  margin-top: 5px;
  position: relative;
  top: 8%;
  font-size: 40px;
  font-weight: 400;
}

.font_style_2 {
  width: 688px;
  margin-top: 46px;
  position: relative;
  top: 10%;
  font-size: 18px;
  color: rgb(62, 58, 57);
  line-height: 1.5;
  text-align: justifyLeft;
}

.font_w {
  width: 626px;
}

.mr {
  margin-top: 91px;
  margin-bottom: 60px;
}

.pos_rit_bg {
  position: absolute;
  bottom: 9%;
  width: 100%;
  height: 120px;
  border-radius: 14px;
  background: #ffffffab;
}

.flex_1 {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-left: 5%;
  /* padding-top: 98px; */
  position: absolute;
  bottom: 12%;
  z-index: 2;
}

.pos_rit {
  position: relative;
}

.font_style_fx {
  color: #92ab26;
  font-size: 70px;
  font-weight: 700;
}

.font_pos {
  font-size: 18px;
  color: #92ab26;
  position: absolute;
  top: 20px;
  right: -33px;
}

.font_pospl {
  right: -40px;
}

.font_pos_1 {
  position: absolute;
  bottom: -14px;
  font-size: 18px;
  padding-left: 8px;
  /* text-align: center; */
  /* left: 20px; */
}

.font_pos_1_3 {
  padding-left: 10px;
  width: 120px;
}

.bai {
  /* color: #fff; */
}

.flex_1 .pos_rit:nth-of-type(2) {

  /* color: #fff; */
  .font_pospl {
    right: -15px;
  }
}

.flex_1 .pos_rit:nth-of-type(3) {
  .font_pospl {
    right: -25px;
  }
}

.flex_1 .pos_rit:nth-of-type(4) {
  .font_pos_1 {
    width: 100px;
    padding-left: 2px;
  }
}

.break {
  word-break: keep-all;
  white-space: nowrap;
}

.green {
  color: #92ab26;
  /* text-shadow: 2px 2px 2px #738526; */
}

.shadow {
  color: #92ab26;
  /* text-shadow: 5px 5px 6px #738526; */
}

.shadow1 {
  /* text-shadow: 5px 5px 6px #00000091; */
}

.shadow2 {
  color: #3e3a39;
  /* text-shadow: 1px 1px 2px #3E3A39; */
}
</style>