/*
 * @Description: 
 * @Author: wangying
 * @Date: 2021-09-26 10:48:48
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './css/base.less'
import less from 'less'
import config from "./config";

// 滚动条
import vuescroll from 'vuescroll';
import 'amfe-flexible';
Vue.use(vuescroll);

// 全局axios
import Axios from "./Axios";
Vue.use(Axios);
Vue.prototype.baseurl = config.baseurl;

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/iconfont/iconfont.css'
Vue.prototype.hub = new Vue()
Vue.use(ElementUI)
Vue.use(less)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  data() {
    return {
    }
  },
  render: h => h(App)
}).$mount('#app')
