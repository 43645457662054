<!--
 * @Description: 
 * @Author: wangying
 * @Date: 2021-09-28 16:48:41
-->
<template>
  <div class="ptn">
    <div class="Impact">Join Green Supply Chain <br> Action Now</div>
    <div v-if="$store.state.language === 'cn'" Impact>加入绿链</div>
    <div v-if="$store.state.language === 'cn'" Impact>马上行动</div>
    <div @click="rote" class="more fx_1">
      <p v-if="$store.state.language === 'cn'">点击申请 </p>
      <p v-else>Apply </p>
      <p class="iconRotate1"><i class="font_icon iconfont icon-jiantou_qiehuanzuo_o"></i></p>
    </div>

  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['typeLists'])
  },
  methods: {
    ...mapActions(['changeType', 'changeSubType']),
    rote() {
      this.changeType(this.typeLists[5]);
      this.changeSubType(this.typeLists[5].subMap[0]);
      this.$router.push({ name: 'Guide' })
    }
  }

}
</script>
<style lang="less" scoped>
.fx_1 {
  display: flex;
}

.ptn {
  position: relative;
  text-align: right;

  div:nth-of-type(1) {
    padding-top: 220px;
    margin-bottom: 50px;
    color: #1c4500;
    font-size: 50px;
    font-weight: 700;
  }

  div:nth-of-type(2) {
    margin-bottom: 10px;

    color: #92ab26;
    font-size: 30px;
    font-weight: 700;
  }

  div:nth-of-type(3) {
    color: #92ab26;
    font-size: 60px;
    font-weight: 700;
  }
}

.more {
  position: absolute;
  bottom: -100px;
  right: 0;
  margin-top: 50px;
  justify-content: center;
  width: 200px;
  height: 40px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  background: #92ab26;
  border: 1px solid #92ab26;
  font-size: 19px;

  p:nth-child(1) {
    margin-right: 20px;
    color: white;
  }

  .iconRotate1 {
    line-height: 41px;
    display: inline-block;

    color: #fff;
    transform: rotate(180deg);
    transform: rotateY(180deg);

    .font_icon {
      font-size: 30px;
    }
  }
}
</style>
