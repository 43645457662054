<!--
 * @Description: 
 * @Author: wangying
 * @Date: 2021-10-08 10:33:34
-->
<template>
  <div>
    <p class="font_si Impact"  v-if="$store.state.language === 'cn'" v-html="headerNmaeCh"></p>
    <p class="font_si Impact"  v-else v-html="headerNmae"></p>
    <p class="font_sy Impact"  v-if="$store.state.language === 'cn'" v-html="headerNmae"></p>
  </div>
</template>
<script>
export default {
  props: ['headerNmae', 'headerNmaeCh'],
  data() {
    return {

    }
  }
}
</script>
<style lang="less" scoped>
.font_si {
  font-size: 35px;
  font-weight: 700;
  color: #5a6f18;
  margin-bottom: 10px;
}
.font_sy {
  font-size: 25px;
   font-weight: 700;
  color: #92ab26;
}
</style>