<!--
 * @Description: 
 * @Author: wangying
 * @Date: 2021-10-08 14:15:46
-->
<!--
 * @Description: 
 * @Author: wangying
 * @Date: 2021-10-08 13:57:15
-->
<!--
 * @Description: 
 * @Author: wangying
 * @Date: 2021-10-08 10:21:49
-->
<template>
  <div class="sty">
    <div class="title">
      <ListTitle :headerNmae="headerNmae" :headerNmaeCh="headerNmaeCh"></ListTitle>
    </div>
    <p class="hr_1"></p>
    <div>
      <div v-loading="loading" class="ofw" @mouseover="mouseover" @mouseout="mouseout">
        <vue-scroll ref="flow" @handle-scroll="handleScroll" :ops="ops">
          <div style="width: 93%;" v-for="(item, index) in list" :key="index">

            <div class="fx htd" @click="findS(item.articleId, index)">
              <div class="date ">
                <p class="ys">{{ dateFormat(item.createdat, 'MM-dd') }}</p>
                <p class="ys1">{{ dateFormat(item.createdat, 'yyyy') }}</p>
                <p class="brder"></p>
              </div>
              <div class="row">{{ item.articleName.length > 30 ? item.articleName.slice(0, 28) + '...' : item.articleName
              }}
              </div>
              <div v-show="!item.flag" class="adds">+</div>
              <div v-show="item.flag" class="adds">-</div>
            </div>
            <div class="ProcurementContentRow" v-if="item.flag">
              <div class="main flex">
                <div class="file" v-for="(val, ins) in item.nodeVoList" :key="ins">
                  <span>附件{{ ins + 1 }}：</span>
                  <span @click="dldFile(val.noteId)" class="filecolor pointer">{{ val.nodeName }}</span>
                </div>
              </div>
              <div style="line-height: 2" v-html="item.content">
              </div>
            </div>
          </div>
        </vue-scroll>
      </div>
    </div>
  </div>
</template>
<script>
import vuescrolljs from '@/mixin/index.js'
import ListTitle from '@/components/reuse/listTitle'
import { articleListByPage, articleDetail } from '@/api'
import dateFormat from '@/utils/time'
import '@/css/content.less'

export default {
  mixins: [vuescrolljs],
  data() {
    return {
      dateFormat,
      loading: false,
      headerNmaeCh: '<p style="color: #92ab26;">绿色供应链黑名单评审规则</p>',
      headerNmae: '<p style="color: #747374;">GSC Action Black List Criterion</p>',
      list: [],
      flag: -1,
      pages: {
        page: 0,
        limit: 999
      },
    }
  },
  mounted() {
    this.getApiList()
  },
  methods: {
    getApiList() {
      const params1 = {
        ...this.pages,
        catCode: "000003%7C000041",
        isEn: this.$store.state.language === 'en' ? 1 : 0
      }
      this.loading = true
      articleListByPage(params1).then((res) => {
        this.list = res.data.list
        this.loading = false
      })
    },
    dldFile(id) {
      window.location.href =
        `http://gsc.see.org.cn/file/file/api/download?route=file&nodeId=` + id;
    },
    findS(id, index) {
      this.list = this.list.map((item, i) => {
        if (index == i) {
          item.flag = !item.flag
          if (item.flag) {
            const params1 = {
              articleId: id,
            };
            this.loading = true;
            articleDetail(params1).then((res) => {
              this.loading = false;

              item.nodeVoList = res.data.nodeVoList || [];
              // this.list = [res.data]
              // this.content =
            });
          }
        }
        return item
      })
    },
    handleScroll(vertical, horizontal, nativeEvent) {
      const flowarr = this.$refs["flow"].getScrollProcess();


      // if (+flowarr.v.toFixed(1) > 0.8) {
      //   this.pages.limit = this.pages.limit + 20
      //   this.getApiList()
      // }


    },
    mouseover(e) {
      // console.log(e,'1332234');
      this.hub.$emit('mouseover1')
    },
    mouseout(e) {
      // console.log(e,'-------');
      this.hub.$emit('mouseout1')
    }
  },
  components: {
    ListTitle
  }
}
</script>
<style lang="less" scoped>
.sty {
  padding: 55px 10px 0px 60px;
}

.hr_1 {
  margin-top: 34px;
  margin-right: 60px;
  margin-bottom: 10px;
  border-top: 2px solid #92ab26;
}

.fx {
  display: flex;
}

.htd {
  height: 60px;
  width: 782px;
  border-bottom: 1px solid #b4c56a;
  background: #615f5f;
}

.greenbr {
  width: 782px;
  height: 74px;
  margin-bottom: 14px;
  border-top: 2px solid #231815;
  border-bottom: 1px solid #ebebeb;
}

.date {
  width: 100px;
  padding-left: 20px;
  position: relative;

  .ys {
    margin-top: 8px;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
  }

  .ys1 {
    margin-top: -2px;
    color: #fff;
    font-size: 14px;
  }

  .brder {
    height: 40px;
    position: absolute;
    top: 10px;
    right: 0;
    border-right: 1px solid #fff;
  }
}

.row {
  flex: 1;
  padding-left: 35px;
  line-height: 60px;
  font-size: 20px;
  color: #f6f8f3;
}

.adds {
  width: 20px;
  height: 20px;
  line-height: 15px;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 50%;
  color: #fff;
  position: relative;
  top: 20px;
  left: -8px;
}

.ofw {
  height: 700px;
  overflow: auto;
}

.main {
  min-height: 20px;

  color: #5e5d5d;

  .file {
    margin: 10px;
    width: 30%;
  }

  .filecolor {
    color: #337ab7;
  }
}

.flex {
  display: flex;
  flex-wrap: wrap;
}
</style>
<style lang="less" >
.ProcurementContentRow {
  img {
    width: 100% !important;
    height: auto !important;
  }
}
</style>