/*
 * @Description: 
 * @Author: wangying
 * @Date: 2021-10-19 20:04:36
 */
const ops = {
  ops: {
    // vuescroll
    vuescroll: {},
    scrollPanel: {},
    //滚动条滚动的地方
    rail: {},
    bar: {
      /** 当不做任何操作时滚动条自动消失的时间 */
      // showDelay: 500,
      /** Specify bar's border-radius, or the border-radius of rail and bar will be equal to the rail's size. default -> false **/
      // specifyBorderRadius: false,
      /** 是否只在滚动的时候现实滚动条 */
      // onlyShowBarOnScroll: true,
      /** 是否保持显示 */
      keepShow: true,
      /** 滚动条颜色, default -> #00a650 */
      background: '#57802b',
      /** 滚动条透明度, default -> 1  */
      opacity: 1,
      /** 滚动条的尺寸，默认6px **/
      size: '6px',
      /** Styles when you hover scrollbar, it will merge into the current style */
      hoverStyle: false
    },
    scrollButton: {
      enable: false
      // mousedownStep: 
    }
  }
}
export default  ops
