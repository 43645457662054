<!--
 * @Description: 
 * @Author: wangying
 * @Date: 2021-09-28 17:33:19
-->
<template>
  <div class="ptn">
    <div class="flex gsc">
      <div class=" links">
        <p>绿链行动 <br>推进委员会成员单位</p>
        <p>GSC Action Promotion <br> Committee Member</p>
      </div>
      <div class="flex linksR">
        <ul>
          <li>
            <a href="http://see.org.cn" target="_blank">
              <p>阿拉善SEE</p>
              <p>http://see.org.cn</p>
            </a>
          </li>
          <li>
            <a href="http://www.vanke.com" target="_blank">
              <p>万科集团</p>
              <p>http://www.vanke.com</p>
            </a>
          </li>
          <li>
            <a href="http://www.cabee.org/" target="_blank">
              <p>中国建筑节能协会</p>
              <p>http://www.cabee.org/</p>
            </a>

          </li>

          <li>
            <a href="http://www.bnbm.com.cn/" target="_blank">
              <p>北新建材</p>
              <p>http://www.bnbm.com.cn/</p>
            </a>
          </li>
        </ul>
        <ul>
          <li>
            <a href="http://www.cura.com.cn/" target="_blank">
              <p>中城联盟</p>
              <p>http://www.cura.com.cn/</p>
              <!-- <p style="height: 16px"></p> -->
            </a>
          </li>
          <li>
            <a href="http://www.landsea.cn" target="_blank">
              <p>朗诗控股集团</p>
              <p>http://www.landsea.cn</p>
            </a>
          </li>

          <li>
            <a href="http://www.cabee.org/" target="_blank">
              <p>中国房地产业协会</p>
              <p>http://www.fangchan.com</p>
            </a>

          </li>
          <li>
            <a href="http://www.elitef.org.cn" target="_blank">
              <p>精瑞人居发展基金会</p>
              <p>http://www.elitef.org.cn</p>
            </a>
          </li>
        </ul>
        <ul>
          <li>
            <a href="http://www.chinahouse.info" target="_blank">
              <p>全联房地产商会</p>
              <p>http://www.chinahouse.info</p>
            </a>
          </li>
          <!-- <li>
                      <a href="http://www.wwfchina.org" target="_blank">
                        <p>WWF(世界自然基金会)</p>
                        <p>http://www.wwfchina.org</p>
                      </a>
                    </li> -->

          <li>
            <a href="http://www.mepfeco.org.cn/" target="_blank">
              <p>生态环境部对外合作与交流中心</p>
              <p>http://www.mepfeco.org.cn/</p>
            </a>
          </li>
          <li>
            <a href="http://www.ipe.org.cn" target="_blank">
              <p>公众环境研究中心(IPE)</p>
              <p>http://www.ipe.org.cn</p>
            </a>
          </li>

          <li>
            <a href="http://www.iGREEN.org" target="_blank">
              <p>友绿</p>
              <p>http://www.iGREEN.org</p>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="caseNo">
      <p>走进GSC | 新闻中心| 招贤纳土| 联系我们| 内部用户登录 | 技术支持:恩创信息</p>
      <p>Copyright 2015 GSC . all rights reserved京ICP备06008888号-1版权所有: GSC </p>
    </div>
  </div>
</template>
<script>
export default {

}
</script>
<style lang="less" scoped>
.ptn {
  // position: relative;
}

.flex {
  display: flex;
}

.gsc {
  padding-top: 50px;

  .links {
    padding: 50px 30px 50px 50px;
    padding-top: 0px;
    text-align: right;
    height: 252px;
    width: 540px;
    color: #fff;
    border-right: 1px solid #72941e;

    p:nth-of-type(1) {
      margin-top: 34px;

      padding-right: 0px;
      font-size: 28px;
    }

    p:nth-of-type(2) {
      margin-top: 6px;
      // padding-left: 236px;
      padding-right: 0px;

      font-size: 12px;
      // text-align: left;
      text-align: right;
    }
  }

  .linksR {
    flex: 1;

    ul {
      flex: 1;

      &:nth-child(2) {
        flex: 1.2;
      }

      li {
        &:nth-child(1) {
          margin-top: 14px;
        }

        padding-left: 50px;
        margin-top: 22px;

        p {
          color: #fff;

          &:nth-child(1) {
            font-size: 15px;
            font-weight: 500;
          }

          &:nth-child(2) {
            font-size: 12px;
            font-weight: 300;
          }
        }
      }
    }
  }
}

.caseNo {
  // margin-top: 40px;
  position: absolute;
  // width: 100%;
  // bottom: -80px;
  bottom: 0px;
  right: 50%;
  transform: translate(50%, -30%);

  p {
    text-align: center;
    font-weight: 500;
    color: #fff;
  }
}
</style>