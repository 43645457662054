import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    language: 'cn',
    typeLists: [],
    currentType: null,
    currentSubType: null
  },
  mutations: {
    setLanguage (state, payload) {
      state.language = payload
    },
    setTypeLists (state, payload) {
      state.typeLists = payload
    },
    setCurrentType (state, payload) {
      state.currentType = payload
    },
    setCurrentSubType (state, payload) {
      state.currentSubType = payload
    }
  },
  actions: {
    changeLanguage ({ commit }, payload) {
      commit('setLanguage', payload)
    },
    changeType ({ commit }, payload) {
      commit('setCurrentType', payload)
    },
    loadTypeLists ({ commit }, types) {
      // 这里可以添加异步操作，比如从服务器获取数据
      commit('setTypeLists', types)
    },
    changeSubType ({ commit }, payload) {
      commit('setCurrentSubType', payload)
    }
  },
  getters: {
    language: state => state.language,
    isEnglish: state => state.language === 'en',
    typeLists: state => state.typeLists,
    currentType: state => state.currentType,
    currentSubType: state => state.currentSubType
  },
  plugins: [
      createPersistedState({
        key: 'gsc-vuex',
        storage: window.localStorage,
        render(state) {
          return {...state}
        }
      })
  ]
})
