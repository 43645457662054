<!--
 * @Description: 
 * @Author: wangying
 * @Date: 2021-09-26 10:48:48
-->
<template>
  <!-- 主页面 -->
  <div class="home w_min scrollH">

    <!-- 轮播 -->
    <div v-show="$route.meta.flag" class="swipervertical"
      :style="'height: ' + innerHeight1 + 'px; wifth: ' + innerWidth + 'px;'">
      <div class="swiperys">
        <div class="swiperys" id="swiper23">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <!-- 首屏 -->
              <!-- class="swiper-slide" -->
              <div class="" :style="'height: ' + innerHeight1 + 'px; wifth: ' + innerWidth + 'px;'">
                <div class="top_bg" :style="'background: url(' + imageBg + ');'">
                  <div class="w_auto main_body ">
                    <img class="logo" src="@/assets/logo1.png" alt="">
                    <div class="nav_meun clearfix">
                      <el-menu background-color='#00000000' active-text-color="#fff" text-color='#fff'
                        :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
                        <el-menu-item index="0" @click="rlink">
                          {{ $store.state.language === 'cn' ? '首页' : 'Home' }}
                        </el-menu-item>

                        <template v-for="(type, i) in typeLists">
                          <el-submenu v-if="type.subMap.length > 0" :key="type.typeId" :index="`${i + 1}`">
                            <template slot="title">{{ $store.state.language === 'en' && type.eName ? type.eName :
        type.typeName
                              }}</template>
                            <el-menu-item v-for="(sub, index) in type.subMap" :index="`${i}-${index}`" :key="sub.typeId"
                              @click="changeType(type), updateSubType(sub), $router.push({ path: `/content/${type.eName.replaceAll(' ', '')}/${sub.eName ? sub.eName.replaceAll(' ', '') : ''}` })">
                              {{
        $store.state.language === 'cn' ? sub.typeName : (sub.eName || sub.typeName)
      }}
                            </el-menu-item>
                          </el-submenu>
                          <el-menu-item v-else :index="`${i + 1}`" :key="type.typeId + '-'"
                            @click="changeType(type), $router.push({ path: `/content/${type.eName.replaceAll(' ', '')}` })">
                            {{ $store.state.language === 'en' && type.eName ? type.eName :
        type.typeName }}
                          </el-menu-item>
                        </template>
                        <el-menu-item index="14">
                          <span @click="updateLanguage('en')">EN</span>{{ '\xa0\xa0' }} | {{ '\xa0\xa0' }}<span
                            @click="updateLanguage('cn')">CN</span>
                        </el-menu-item>

                      </el-menu>
                      <!-- <div class="ENCN pointer" style="color: #fff;">
                        
                            </div> -->
                    </div>
                    <div class="brief">
                      <p class="brief_1 Impact" style="letter-spacing:1px;">Green Supply Chain Action in</p>
                      <p class="brief_1 Impact" style="letter-spacing:1px;">China’s Real Estate Industry</p>
                      <p class="brief_1 mtop Impact" v-if="$store.state.language === 'cn'" style="letter-spacing:2px;">
                        中国房地产行业</p>
                      <p class="brief_1 Impact" v-if="$store.state.language === 'cn'" style="letter-spacing:2px;">
                        绿色供应链行动
                      </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <!-- 二页 -->
            <!-- 中间部位 -->
            <div class="swiper-slide hit">
              <!-- 关于绿链行动 -->
              <div class="about_bg hit">
                <div class="about_bg1"></div>
                <div class="w_auto  hit">
                  <AboutGsc></AboutGsc>
                </div>
              </div>
            </div>
            <!-- 三页 -->
            <div class="swiper-slide hit">
              <!-- 发起组织名单 -->
              <div class="organization hit">
                <div class="organization_bg hit">
                  <div class="w_auto hit">
                    <Organization></Organization>
                  </div>
                </div>
              </div>
            </div>
            <!-- 四页 -->
            <div class="swiper-slide">
              <!-- 最新动态 -->
              <div class="latestnews_bg">
                <div class="greenCategories_bg1"></div>
                <div class="w_auto hit">
                  <Latestnews></Latestnews>
                </div>
              </div>
            </div>
            <!-- 五页 -->
            <div class="swiper-slide">
              <!-- 绿色采购指南 -->
              <div class="GreenProcurement_bg">
                <div class="w_auto">
                  <GreenProcurement></GreenProcurement>
                </div>
              </div>
            </div>
            <!-- 六页 -->
            <div class="swiper-slide">
              <!-- 绿色采购品类清单 -->
              <div class="greenCategories_bg">
                <!-- <div v-if="CategoriesNum == 0" class="greenCategories_bg1"></div>
                      <div v-if="CategoriesNum == 1" class="greenCategories_bg1"></div>
                      <div v-if="CategoriesNum == 4" class="greenCategories_bg1"></div> -->
                <div class=" hit" :style="{ width: innerWidth + 'px' }">
                  <GreenCategories @CategoriesBg="Categoriesbg"></GreenCategories>
                </div>
              </div>
            </div>
            <!-- 六页-绿名单 -->
            <div class="swiper-slide">
              <!-- 绿色采购品类清单 -->
              <!-- <div class="greenCategories_bg"> -->
              <!-- <div v-if="CategoriesNum == 0" class="greenCategories_bg1"></div>
                      <div v-if="CategoriesNum == 1" class="greenCategories_bg1"></div>
                      <div v-if="CategoriesNum == 4" class="greenCategories_bg1"></div> -->
              <!-- <div class=" hit" :style="{width: innerWidth+'px'}"> -->
              <swiper2></swiper2>
              <!-- </div> -->
              <!-- </div> -->
            </div>
            <!-- 七页 -->
            <div class="swiper-slide">
              <!-- 绿色供应链白榜 -->
              <div class="greenSupply_bg">
                <div class="w_auto greenSupply">
                  <GreenSupply></GreenSupply>
                </div>
              </div>
            </div>
            <!-- 八页 -->
            <div class="swiper-slide">
              <div class="ofly" :style="'height: ' + innerHeight1 + 'px'">
                <vue-scroll @handle-scroll="handleScroll" :ops="ops">
                  <!-- 加入绿链马上行动 -->
                  <div class="greenChain_bg" :style="'height: ' + innerHeight1 + 'px'">
                    <div class="w_auto greenSupply">
                      <GreenChain></GreenChain>
                    </div>
                  </div>
                  <!-- 底部nav导航栏栏 -->
                  <div v-if="$route.meta.btmNav" class="btmNav_bg">
                    <div class="btm_bg">
                      <div class="btm_logo">
                        <div
                          style="position: absolute;bottom: 0px;left: 0px;right: 0px;height: 20%;background: #7b9f20;">
                        </div>
                        <img style="position: absolute;left: 0px;top: 25%;width: 18%;height: auto;"
                          src="../assets/btm_logo_bg1.png" />
                        <div class="w_auto greenSupply">
                          <BtmNav></BtmNav>
                        </div>
                      </div>
                    </div>
                  </div>
                </vue-scroll>
              </div>

            </div>
            <!-- <div class="swiper-slide">Slide 9</div>
                  <div class="swiper-slide">Slide 10</div> -->
          </div>
        </div>
      </div>

      <!-- Add Pagination -->
      <div class="swiper-pagination_23"></div>
    </div>
    <!-- 首屏 -->
    <!-- class="swiper-slide" -->
    <div v-if="!$route.meta.flag" class="" :style="'height: ' + innerHeight1 + 'px'">
      <div class="top_bg" :style="'background: url(' + imageBg + ');'">
        <div class="w_auto main_body ">
          <img class="logo" src="@/assets/logo1.png" alt="">
          <div class="nav_meun clearfix">
            <el-menu background-color='#00000000' active-text-color="#fff" text-color='#fff'
              :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
              <el-menu-item index="0" @click="rlink">
                {{ $store.state.language === 'cn' ? '首页' : 'Home' }}
              </el-menu-item>
              <template v-for="(type, i) in typeLists">
                <el-submenu v-if="type.subMap.length > 0" :key="type.typeId" :index="`${i + 1}`">
                  <template slot="title">{{ $store.state.language === 'en' && type.eName ? type.eName :
        type.typeName
                    }}</template>
                  <el-menu-item v-for="(sub, index) in type.subMap" :index="`${i}-${index}`" :key="sub.typeId"
                    @click="changeType(type), updateSubType(sub), $router.push({ path: `/content/${type.eName.replaceAll(' ', '')}/${sub.eName.replaceAll(' ', '')}` })">
                    {{
        $store.state.language === 'cn' ? sub.typeName : (sub.eName || sub.typeName)
      }}
                  </el-menu-item>
                </el-submenu>
                <el-menu-item v-else :index="`${i + 1}`" :key="type.typeId + i"
                  @click="changeType(type), updateSubType(null), $router.push({ path: `/content/${type.eName.replaceAll(' ', '')}` })">
                  {{ $store.state.language === 'en' && type.eName ? type.eName :
        type.typeName }}
                </el-menu-item>
              </template>
              <el-menu-item index="14">
                <span @click="updateLanguage('en')">EN</span>{{ '\xa0\xa0' }}|{{ '\xa0\xa0' }}<span
                  @click="updateLanguage('cn')">CN</span>
              </el-menu-item>

            </el-menu>
            <!-- <div class="ENCN pointer" style="color: #fff;">
            
                  </div> -->
          </div>
          <div class="brief">
            <p class="brief_1 Impact" style="letter-spacing:1px;">Green Supply Chain Action in</p>
            <p class="brief_1 Impact" style="letter-spacing:1px;">China’s Real Estate Industry</p>
            <p class="brief_1 mtop Impact" v-if="$store.state.language === 'cn'" style="letter-spacing:2px;">中国房地产行业</p>
            <p class="brief_1 Impact" v-if="$store.state.language === 'cn'" style="letter-spacing:2px;">绿色供应链行动</p>
          </div>
        </div>

      </div>
    </div>
    <router-view></router-view>

    <!-- <div v-if="$route.meta.flag"> -->

    <!-- </div> -->
    <!-- 底部nav导航栏栏 -->

    <div v-if="$route.meta.btmNav && !$route.meta.flag" class="btmNav_bg">
      <div class="btm_bg">
        <div class="btm_logo">
          <div style="position: absolute;bottom: 0px;left: 0px;right: 0px;height: 20%;background: #7b9f20;"></div>
          <img style="position: absolute;left: 0px;top: 25%;width: 18%;height: auto;"
            src="../assets/btm_logo_bg1.png" />
          <div class="w_auto greenSupply">
            <BtmNav></BtmNav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/css/elememtUi.less'
// @ is an alias to /src
import AboutGsc from '@/components/aboutGsc'
import Organization from '@/components/organization'
import Latestnews from '@/components/latestnews'
import GreenProcurement from '@/components/greenProcurement'
import GreenCategories from '@/components/greenCategories'
import GreenSupply from '@/components/greenSupply'
import GreenChain from '@/components/greenChain'
import BtmNav from '@/components/btmNav'
import vuescrolljs from '@/mixin/index.js'
import swiper2 from "@/components/greenCategories/swiper2.vue";
import { frontChannelOneLevelArticleType, getFrontChannelArticleTypeSubApi } from '@/api'
import { mapActions, mapGetters } from 'vuex';
export default {
  // mixins: [vuescrolljs],
  name: 'Home',
  data() {
    return {
      isval: true,
      home: null,
      scrolltbl: true,
      mySwiper1: null,
      innerHeight1: 180,
      innerWidth: '',
      menuList: [],
      activeIndex: '0',
      activeIndex2: '1',
      bgNum: 0,
      CategoriesNum: 0,
      imgs: [
        require('@/assets/bg-1.jpg'), // 0 
        require('@/assets/theCover_01.jpg'),
        require('@/assets/theCover_03.jpg'),
        require('@/assets/theCover_02.jpg'),
        require('@/assets/theCover_04.jpg'),
        require('@/assets/theCover_01.jpg'),
        // require('@/assets/theCover_06.jpg'), //  6白名单
        require('@/assets/theCover_07.jpg'), //  6白名单
        require('@/assets/theCover_07.jpg'), // 7 绿名单
        require('@/assets/theCover_08.jpg'), // 8 黑名单
        require('@/assets/bg-1.jpg'), // 9 商检测
        require('@/assets/theCover_10.jpg'), // 10 参与指南
        require('@/assets/theCover_11.jpg'), // 11 各品类联系人
        require('@/assets/theCover_12.jpg'), // 12 最新动态
        require('@/assets/theCover_13.jpg'), // 13 联系我们

      ],
      CategoriesImgBg: [
        require('@/assets/Categories_bg_01.jpg'),
        require('@/assets/Categories_bg_02.jpg'),
        require('@/assets/Categories_bg_03.jpg'),
        require('@/assets/Categories_bg_04.jpg'),
        require('@/assets/Categories_bg_05.jpg'),
        require('@/assets/Categories_bg_06.jpg'),
      ],
      document: '',
      isenableFlag: false,
      typeList: []
    };
  },
  mounted() {
    this.activeIndex = String(this.$route.meta.flagNam)
    console.log(this.activeIndex, 'activeIndex');
    this.bgNum = this.$route.meta.flagNam

    // ---------------
    this.innerHeight1 = window.innerHeight
    this.innerWidth = window.innerWidth
    window.onresize = () => {
      this.innerHeight1 = window.innerHeight
    }
    window.addEventListener("resize", () => {
      this.innerHeight1 = window.innerHeight
      this.innerWidth = window.innerWidth
    });
    // 轮播
    const _this = this
    this.mySwiper1 = new Swiper('#swiper23', {
      direction: 'vertical',
      speed: 1000,
      preventInteractionOnTransition: true,
      keyboard: true,
      mousewheel: {
        releaseOnEdges: true,
        // forceToAxis: true,
      },
      on: {
        slideChangeTransitionStart: function (swiper, event) {
          //你的事件
          // this.disable();

          // _this.mySwiper1.mousewheel.disable()

          // window.scrollTo(0, 0)
        },
        slideChangeTransitionEnd: function () {


          // _this.mySwiper1.mousewheel.enable()

        },
      },
      // pagination: {
      //   // el: '.swiper-pagination_23',
      //   // clickable: true,
      // },
    });
    document.addEventListener('wheel', this.isenable, false);

    // -----------------------------

    this.hub.$on('theCover', (val) => {
      this.activeIndex = String(this.$route.meta.flagNam)
      this.bgNum = this.$route.meta.flagNam
    })
    // 暂停轮播
    this.hub.$on('mouseover1', () => {
      this.isenableFlag = true

      this.mySwiper1.mousewheel.disable();
    })
    // 开启轮播
    this.hub.$on('mouseout1', () => {
      this.isenableFlag = false
      this.mySwiper1.mousewheel.enable();
    })

    this.getFrontChannel();
  },
  methods: {

    ...mapActions(['changeLanguage', 'loadTypeLists', 'changeType', 'changeSubType']),
    // EN() {
    //   location.href = 'http://en.gsc.see.org.cn/'
    // },
    handleScroll(vertical, horizontal, nativeEvent) {
      if (vertical.scrollTop > 0) {
        this.mySwiper1.mousewheel.disable();
      } else if (vertical.scrollTop < 20) {
        this.mySwiper1.mousewheel.enable();
      }
    },
    updateLanguage(l) {
      this.changeLanguage(l);
      this.hub.$emit('changeLanguage')
    },
    updateSubType(subType) {
      this.changeSubType(subType);
      this.hub.$emit('currentSubType');
      setTimeout(() => {
        document.getElementsByClassName('content-view')[0] && document.getElementsByClassName('content-view')[0].scrollIntoView(true)
      }, 300);
    },
    async getFrontChannel() {
      const typeLists = this.typeLists;
      frontChannelOneLevelArticleType().then(async res => {
        if (res.data && res.data.length) {

          for (const item of res.data) {
            const r = await getFrontChannelArticleTypeSubApi({ typeId: item.typeId });
            item.subMap = r.data;

          }
          if (JSON.stringify(res.data) !== JSON.stringify(typeLists)) {
            this.loadTypeLists(res.data);
          }
        }
      });
    },
    handleSelect(key, keyPath) {
      // console.log(key, keyPath);
    },
    Categoriesbg(val) {
      // console.log(val);
      this.CategoriesNum = val - 1
    },
    rlink() {
      this.bgNum = 0
      this.activeIndex = '0'
      this.$router.push({ name: 'Home' })
    },
    // 
    isenable(e) {

      if (this.isenableFlag) {
        return
      }
      if (e.deltaY > 1) {
        if (e.deltaY > 50) {
          this.mySwiper1.mousewheel.enable()
        } else {
          this.mySwiper1.mousewheel.disable()
        }
      } else {
        if (e.deltaY < -50) {
          this.mySwiper1.mousewheel.enable()
        } else {
          this.mySwiper1.mousewheel.disable()
        }
      }
    }
  },
  computed: {
    ...mapGetters(['typeLists']),
    imageBg() {
      // console.log(this.bgNum,'--------',this.imgs.length);
      return this.bgNum < this.imgs.length ? this.imgs[this.bgNum] : this.imgs[0]
    }
  },
  components: {
    AboutGsc,
    Organization,
    Latestnews,
    GreenProcurement,
    GreenCategories,
    swiper2,
    GreenSupply,
    GreenChain,
    BtmNav
  }
}
</script>
<style lang="less" scoped>
.ENCN {
  margin-top: 19px;

  span:hover {
    color: green;
  }
}

.w_auto1 {
  // margin: 0 auto ;
  width: 100vh;
}

.hit {
  height: 100%;
}

.scale {
  transform: scale(0.9, 1);
}

.top_bg {
  height: 100%;
  /* background: url("../assets/bg-1.jpg") no-repeat; */
  background-repeat: no-repeat;
  background-size: 100% 100% !important;
}

.logo {
  margin-top: 0.52rem;
  height: 50px;
  margin-left: -70px;
}

.nav_meun {
  min-width: 1064px;
  max-width: 92%;
  height: 100px;
  display: flex;
  justify-content: right;
  margin-top: 90px;
  float: right;
}

.el-menu.el-menu--horizontal {
  border-bottom: none;
}

.brief {
  margin-top: 280px;
  height: 345px;
}

.brief_1 {
  /* margin-top: 5px; */
  font-size: 50px;
  font-weight: 900;
  color: #fff;
}

.mtop {
  margin-top: 28px;
}

.about_bg {
  position: relative;

  height: 100%;
  /* height: 830px; */

  /* background: url("../assets/about-bg.jpg") no-repeat 0 0 ;
  background-position-x: right; */
}

.about_bg1 {
  position: absolute;
  height: 100%;
  width: 76%;
  right: 0;
  /* height: 830px; */

  background: url("../assets/about-bg.jpg") no-repeat;
  background-size: 108% 138%;
  /* background-position-y:bottom; */
  /* background-position-y: 130px; */
}

.organization {
  height: 100%;
}

.organization_bg {
  height: 80%;
  background: url("../assets/organization-bg.jpg") #83aa3d no-repeat;
  background-blend-mode: multiply;
  background-size: 100% 100%;
}

.latestnews_bg {
  position: relative;
  background-color: #f6f6f6;
  background: url("../assets/latestnews-bg.jpg") #83aa3d no-repeat;
  /* height: 950px; */
  height: 100%;
}

.GreenProcurement_bg {
  height: 100%;
  /* height: 940px; */
  background: url("../assets/GreenProcurement_bg.jpg") no-repeat;
  background-size: 100% 100%;
}

.greenCategories_bg {
  position: relative;
  height: 100%;
  /* height: 948px; */
  // background: url("../assets/Categories_bg_05.jpg")  no-repeat;
  //   /* background-blend-mode: multiply; */
  // background-size: 100% 100% !important;
}

.greenCategories_bg1 {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #ffffffa8;
}

.greenSupply_bg {
  /* height: 964px; */
  height: 100%;
  background: url("../assets/greenSupply_bg.jpg") no-repeat;
  background-size: 100% 100%;
}

.greenSupply {
  height: 100%;
}

.greenChain_bg {
  /* height: 820px; */
  height: 100%;
  background: url("../assets/chain_bg.jpg") no-repeat;
  background-size: 100% 100%;
}

.btmNav_bg {
  height: 400px;
  background: url("../assets/btm_bg1.png") no-repeat center;
  background-size: 65% 65%;
}

.btm_bg {
  height: 100%;
  width: 100%;
  background: url("../assets/btm_bg.png") no-repeat;
  background-size: 100% 100%;
}

.btm_logo {
  height: 100%;
  width: 100%;
  // background: url("../assets/btm_logo_bg.png") no-repeat bottom;
  background-size: 100%;
  position: relative;
}

.ofly {
  overflow: auto;
}

/* 轮播 */
.swipervertical {
  /* height: 1080px; */
  height: 100%;
  overflow: hidden;
}

.swiperys {
  height: 100%;
}

/* .swiper-container_23 {
  width: 100%;
  height: 100%;
} */
.el-menu-item {
  font-size: 18px;
}

.swiper-slide {
  /* text-align: center;
  font-size: 18px;
  background: #fff; */

  /* Center slide text vertically */
  /* display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center; */
}
</style>