<!--
 * @Description:
 * @Author: wangying
 * @Date: 2021-11-13 08:38:51
-->
<template>
  <div class="main_by2 pos greenCategories_bg"
    :style="'background: url(' + CategoriesImgBg2[CategoriesNum] + ')  no-repeat; '">
    <div class="greenCategories_bg1"></div>
    <div class=" w_auto pos">
      <div class="sye_hdr">
        <div v-if="num == false">
          <PageHeader :headerNmae="headerNmae4" :headerNmaeCh="headerNmaeCh5" :flag="flag"></PageHeader>
        </div>
        <!-- <div v-else-if="num == 6">
          <PageHeader :headerNmae="headerNmae4" :headerNmaeCh="headerNmaeCh5" :flag="flag"></PageHeader>
        </div> -->
        <div v-else>
          <PageHeader :headerNmae="headerNmae_5" :headerNmaeCh="headerNmaeCh_5" :flag="flag"></PageHeader>
        </div>
      </div>
      <div class="fx_1">
        <p :style="{ color: num == 4 ? '#fff' : num == 4 ? '#fff' : '' }" class="sy_font">
          0{{ num }}
        </p>
        <p :style="{
          borderBottom:
            num == 4 ? '2px dashed #fff' : num == 4 ? '2px dashed #fff' : ''
        }" class="sy_font_1"></p>
      </div>
      <div style="width: 50%;">
        <p v-if="$store.state.language === 'cn'" :style="{ color: num == 4 ? '#fff' : '' }" class="sy_font font_2" v-html="tatleName.cn"></p>
        <p v-else :style="{ color: num == 4 ? '#fff' : '' }" class="sy_font font_2" v-html="tatleName.es"></p>
        <p  v-if="$store.state.language === 'cn'" :style="{ color: num == 4 ? '#fff' : '' }" class=" font_3" v-html="tatleName.es"></p>
      </div>
      <div class="more fx_1 pointer" @click="hoves">
        <p class=""  v-if="$store.state.language === 'cn'">评价标准</p>
        <p class=""  v-else>Criterion</p>
        &nbsp; &nbsp;
        <p class="iconRotate1">
          <i class="iconfont icon-jiantou_qiehuanzuo_o"></i>
        </p>
      </div>
      <div class=" img_sy">
        <img :src="goodsImg1[CategoriesNum]" alt="" />
      </div>
      <div class="fx_sty">
        <div id="swiper_lis">
          <div class="swiper-wrapper">
            <div class="swiper-slide fx_1  pointer swiper-no-swiping">
              <div v-for="i in 6" :key="i" :class="[num == i ? 'bg_1' : '', 'fx_syle line_h']" @click="Categories(i)">
                {{$store.state.language === 'cn' ? tableData[i-1].name : tableData[i-1].es}}
              </div>

            </div>
            <div class="swiper-slide fx_1  pointer swiper-no-swiping">
              <div v-for="i in [7,8,9]" :key="i" :class="[num == i ? 'bg_1' : '', 'fx_syle line_h']" @click="Categories(i)">
                {{$store.state.language === 'cn' ? tableData[i-1].name : tableData[i-1].es}}
              </div>

            </div>
          </div>
          <div class="swiper-button-prev"></div>
          <!--左箭头。如果放置在swiper外面，需要自定义样式。-->
          <div class="swiper-button-next"></div>
          <!--右箭头。如果放置在swiper外面，需要自定义样式。-->
        </div>
      </div>

      <!-- 弹窗 -->
      <div class="dialog swiper-no-swiping " v-if="dialogVisible">
        <p class="circle-close pointer" @click="cancel">
          <i class="el-icon-circle-close"></i>
        </p>
        <el-card class="box-card">
          <GreenList :datas="datas" :tatleName="tatleName"></GreenList>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
import PageHeader from "@/components/reuse/pageHeader";
import GreenList from "@/components/greenCategories/GreenList";
import { articleListByPage, articleDetail } from '@/api'
export default {
  name: 'greenCategories2',
  data() {
    return {
      dialogVisible: false,
      //   headerNmae: '<p style="color: #1c4500;">Green Procurement  <br/>Categories List </p>',
      //   headerNmaeCh: '<p style="color: #92ab26;">绿色采购品类清单</p>',
      headerNmae4: '<p style="color: #fff;">Green Supply Chain Action  <br/>Green List Criterion</p>',
      headerNmaeCh4: '<p style="color: #fff;">绿色采购品类标准</p>',
      headerNmaeCh5: '<p style="color: #fff;">绿色采购品类标准 — 绿名单</p>',
      headerNmaeCh_5: '<p style="color: #231815;">绿色采购品类标准 — 绿名单</p>',
      headerNmae_5: '<p style="color: #231815;">Green Supply Chain Action  <br/>Green List Criterion </p>',


      flag: false,
      num: 1,
      tatleName: {
        cn:
          '<p display: inline-block;">钢铁企业绿名单评价导则 </p>',
        es:
          '<p display: inline-block;">Green List Criteria of Iron and Steel Enterprises</p>',
        cn1:
          '钢铁企业绿名单评价导则',
        es1:
          'Green List Criteria of Iron and Steel Enterprises'
      },
      datas: {},
      height: '',
      width: '',
      pages: {
        page: 1,
        limit: 10
      },
      CategoriesNum: 0,

      CategoriesImgBg2: [
        require('@/assets/Categories_bg_001.jpg'),
        require('@/assets/Categories_bg_002.jpg'),
        require('@/assets/Categories_bg_003.jpg'),
        require('@/assets/Categories_bg_004.jpg'),
        require('@/assets/Categories_bg_005.jpg'),
        require('@/assets/Categories_bg_006.jpg'),
        require('@/assets/Categories_bg_10.jpg'),
        require('@/assets/Categories_bg_11.jpg'),
        require('@/assets/Categories_bg_12.jpg'),
      ],
      goodsImg1: [
        require('@/assets/goods_01.jpg'),
        require('@/assets/goods_02.jpg'),
        require('@/assets/goods_03.jpg'),
        require('@/assets/goods_04.jpg'),
        require('@/assets/goods_05.jpg'),
        require('@/assets/goods_06.jpg'),
        require('@/assets/goods10.jpg'),
        require('@/assets/goods11.jpg'),
        require('@/assets/goods12-1.jpg'),
      ],
      tableData: [
        {
          name: '钢铁企业绿名单评价导则',
          cn:
              '<p display: inline-block;">钢铁企业绿名单评价导则 </p>',
            es:
              '<p display: inline-block;">Green List Criteria of Iron and Steel Enterprises</p>',
            cn1:
              '钢铁企业绿名单评价导则',
            es1:
              'Green List Criteria of Iron and Steel Enterprises',
              articleId: "WZNR202009171021560003",
        },
        {
          name: '水泥类企业绿名单评价导则',
          cn: "水泥类企业绿名单评价导则 ",
            cn1: "水泥类企业绿名单评价导则 ",
            es:
              "Green List Criteria of Cement Enterprises",
            es1:
              "Green List Criteria of Cement Enterprises",
              articleId: "WZNR202009171056190004"
        },
        {
          name: '铝合金企业绿名单评价导则',
          cn: "铝合金企业绿名单评价导则 ",
            cn1: "铝合金企业绿名单评价导则",
            es: `Green List Criteria of Aluminum Alloy Enterprises`,
            es1: `Green List Criteria of Aluminum Alloy Enterprises`,
            articleId: "WZNR202009171102150005"
        },
        {
          name: '玻璃企业绿名单评价导则',
          cn: "玻璃企业绿名单评价导则",
            cn1: "玻璃企业绿名单评价导则",
            es: `Green List Criteria of Glass Enterprises`,
            es1: `Green List Criteria of Glass Enterprises`,
            articleId: "WZNR202009171113410006"
        },
        {
          name: '烧结墙体材料企业 <br> 绿名单评价导则',
          cn: "烧结墙体材料企业绿名单评价导则",
            cn1: "烧结墙体材料企业绿名单评价导则",
            es: "Green List Criteria of Sintered Wall Material Enterprises",
            es1: "Green List Criteria of Sintered Wall Material Enterprises",
            articleId: "WZNR202009171117540007"
        },
        {
          name: '涂料企业绿名单评价导则',
          cn: "涂料企业绿名单评价导则",
            cn1: "涂料企业绿名单评价导则",
            es: `Green List Criteria of Coating Material Enterprises`,
            es1: `Green List Criteria of Coating Material Enterprises`,
            articleId: "WZNR202009171121320008"
        },
        {
          name: '空气源热泵品质与环保控制',
          cn: "空气源热泵品质与环保控制",
            cn1: "空气源热泵品质与环保控制",
            es: `Green List Criteria of Air Source Heat Pump Enterprises`,
            es1: `Green List Criteria of Air Source Heat Pump Enterprises`,
            articleId: "WZNR202112201930430001"
        },
        {
          name: '岩棉品质与环保控制',
          cn: "岩棉品质与环保控制",
            cn1: "岩棉品质与环保控制",
            es: `Green List Criteria of Rock Wool Enterprises`,
            es1: `Green List Criteria of Rock Wool Enterprises`,
            articleId: "WZNR202112201942560002"
        },
        {
          name: '石\xa0\xa0\xa0\xa0\xa0材',
          cn: "石材",
            cn1: "石材",
            es: `Stone Products`,
            es1: `Stone Products`,
            articleId: "WZNR202112211708230004"
        }
      ]
    };
  },
  mounted() {
    var mySwiper = new Swiper('#swiper_lis', {
      autoplay: false,//可选选项，自动滑动
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })
    console.log(mySwiper);
    let main_by = document.querySelector('.main_by')

    this.height = window.innerHeight
    this.width = window.innerWidth

    this.articleListByPage()
    const params = {
      articleId: "WZNR202009171021560003",
      isEn: this.$store.state.language === 'en' ? 1 : 0
    }
    articleDetail(params).then((res) => {
      this.datas = res.data
    })
    var mySwiper = new Swiper('#swiper_01', {
      autoplay: true,//可选选项，自动滑动
    })
  },
  methods: {
    articleListByPage() {
      // console.log(this.dateY,' =====____');
      const params = {
        ...this.pages,
        catCode: "000003%7C000015",
        year: this.dateY,
        isEn: this.$store.state.language === 'en' ? 1 : 0
      }
      articleListByPage(params).then((res) => {
        this.list = res.data.list
        this.total = res.data.total

      })
    },
    hoves() {
      const params = {
      articleId:  this.tatleName.articleId,
      isEn: this.$store.state.language === 'en' ? 1 : 0
    }
    articleDetail(params).then((res) => {
      this.datas = res.data
      this.dialogVisible = true;
    })
      

      // switch (this.num) {
      //   case 1:
      //     this.$router.push({
      //       name: 'procurementGreens', query: {
      //         id: '000000261'
      //       }
      //     })
      //     break;

      //   case 2:
      //     this.$router.push({
      //       name: 'procurementGreens', query: {
      //         id: '000000262'
      //       }
      //     })
      //     break;
      //   case 3:
      //     this.$router.push({
      //       name: 'procurementGreens', query: {
      //         id: '000000263'
      //       }
      //     })
      //     break;
      //   case 4:
      //     this.$router.push({
      //       name: 'procurementGreens', query: {
      //         id: '000000264'
      //       }
      //     })
      //     break;
      //   case 5:
      //     this.$router.push({
      //       name: 'procurementGreens', query: {
      //         id: '000000265'
      //       }
      //     })
      //     break;
      //   case 6:
      //     this.$router.push({
      //       name: 'procurementGreens', query: {
      //         id: '000000266'
      //       }
      //     })
      //     break;

      //   default:
      //     break;
      // }
    },
    cancel() {
      this.dialogVisible = false;
      this.datas = {}
    },
    Categories(val) {
      // console.log('点击', val);
      this.CategoriesNum = val - 1
      // this.$emit("CategoriesBg", val);
      this.num = val;

      this.tatleName = this.tableData[val-1];
      const params = {
        articleId: this.tatleName.articleId,
        isEn: this.$store.state.language === 'en' ? 1 : 0
      }
      articleDetail(params).then((res) => {
        this.datas = res.data
        // this.list = [res.data]
        // this.content =
      })
    }
  },
  components: {
    PageHeader,
    GreenList
  }
};
</script>
<style lang="less" scoped>
#swiper_lis {
  --swiper-theme-color: #ff6600;
  /* 设置Swiper风格 */
  --swiper-navigation-color: #666;
  /* 单独设置按钮颜色 */
  --swiper-navigation-size: 30px;
  /* 设置按钮大小 */
}

.main_by2 {
  background: url("../../assets/Categories_bg_07.jpg") no-repeat;
  background-size: 100% 100%;
}

.greenCategories_bg {
  position: relative;
  height: 100%;
  /* height: 948px; */
  //   background: url("../assets/Categories_bg_05.jpg") no-repeat;
  /* background-blend-mode: multiply; */
  background-size: 100% 100% !important;
}

.greenCategories_bg1 {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #4a4a4a38;
}

.pos {
  position: relative;
  height: 100%;
}

.sye_hdr {
  padding-top: 80px;
}

.fx_1 {
  display: flex;
}

.sy_font {
  margin-top: 120px;
  margin-right: 30px;
  font-size: 80px;
  font-weight: 700;
  color: #92ab26;
}

.sy_font_1 {
  width: 686px;
  position: relative;
  top: -48px;
  border-bottom: 3px dashed #92ab26;
}

.font_2 {
  color: #231815;
  margin-top: 40px;
  font-size: 40px;
}

.font_3 {
  font-size: 20px;
  font-weight: 700;
  color: #494645;
}

.more {
  position: absolute;
  bottom: 24%;
  margin-top: 50px;
  justify-content: center;
  width: 160px;
  height: 36px;
  line-height: 34px;
  text-align: center;
  color: #fff;
  border: 1px solid #92ab26;
  background: #92ab26;
  font-size: 18px;

  .iconRotate1 {
    line-height: 36px;
    display: inline-block;

    color: #fff;
    transform: rotate(180deg);
    transform: rotateY(180deg);

    i {
      font-size: 30px;
    }
  }
}

.img_sy {
  width: 758px;
  height: 455px;
  position: absolute;
  top: 240px;
  left: 763px;

  img {
    width: 100%;
    height: 100%;
  }
}

.fx_sty {
  position: absolute;
  bottom: 10%;
  width: 100%;
  margin-top: 100px;
  background-color: #fff;
  overflow: hidden;
}

.fx_syle {
  height: 78px;
  flex: 1;
  text-align: center;
  font-size: 16px;
  border-right: 1px solid #92ab26;

  &:last-child {
    border-right: none;
  }
}

.line_h {
  line-height: 78px;
}

.line_h_1 {
  padding: 20px 30px 0 30px;
}

.bg_1 {
  position: relative;
  background-color: #92ab26;

  &::after {
    content: "";
    width: 8px;
    height: 8px;
    display: inline-block;
    background: #92ab26;
    position: absolute;
    top: -5%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform: rotate(45deg);
  }
}

.dialog {
  position: absolute;
  z-index: 2;
  width: 80%;
  height: 800px;
  top: 10%;
  left: 50%;
  transform: translate(-50%, 0);
}

.circle-close {
  position: absolute;
  top: -48px;
  right: -52px;
  color: #fff;
  font-size: 40px;
}

.swiper-button-next,
.swiper-button-prev {
  width: 40px;
  height: 40px;
  margin-top: -18px;
}

.swiper-button-next:hover {
  width: 40px;
  height: 40px;
  background: #1211112e;
  border-radius: 8px;
}

.swiper-button-prev:hover {
  width: 40px;
  background: #1211112e;
  border-radius: 8px;
}
</style>
