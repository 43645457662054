<!--
 * @Description: 
 * @Author: wangying
 * @Date: 2021-09-27 17:05:32
-->
<template>
  <div class="fx_1 posa">
    <div class="sye_hdr">
      <PageHeader :headerNmae='headerNmae' :headerNmaeCh='headerNmaeCh' @goNavto='goNavto' :flag='flag'></PageHeader>
      <div class="width_1">
        <p  v-if="$store.state.language === 'cn'">房地产企业在采购活动中，推广绿色低碳理念，充分考虑环境保护、资源节约、安全健康、循环低碳和回收促进，优先采购和使用节能、节水、节材、健康等有利于环境保护的原材料、产品和服务的行为。</p>
        <p v-else>In procurement, real estate enterprises should promote the green and low-carbon concept, give full consideration to environmental protection, resource conservation, safety and health, circular and low-carbon as well as recycling promotion. At the same time, real estate enterprises should give priority to purchasing and using raw materials, products and services that are conducive to environmental protection such as energy saving, water saving, material saving and health benefiting.</p>
      </div>
      <div class="">
        <div class="wefff">
          <div :style="{ top: top + 'px' }" class="pos"><i class="iconfont icon-youjiantou"></i></div>
          <div class="bg_col" :style="{ backgroundColor: top == 0 ? '#5a822a' : '#92ab26' }" @click="bgtop(0)">
            <p class="font_tatle" v-if="$store.state.language === 'cn'">绿色采购指南</p>
            <p class="font_tatle" v-else>Green Procurement Guide</p>
            <p class="font_tatle_e" v-if="$store.state.language === 'cn'">Green Procurement Guide</p>
          </div>
          <div :style="{ backgroundColor: top == bg_colHeight ? '#5a822a' : '#92ab26' }" class="bg_col"
            @click="bgtop(bg_colHeight)">
            <p class="font_tatle" v-if="$store.state.language === 'cn'">白名单评审规则</p>
            <p class="font_tatle" v-else>White List Criterion</p>
            <p class="font_tatle_e" v-if="$store.state.language === 'cn'">White List Criterion</p>
          </div>
          <div class="bg_col" :style="{ backgroundColor: top == bg_colHeight * 2 ? '#5a822a' : '#92ab26' }"
            @click="bgtop(bg_colHeight * 2)">
            <p class="font_tatle" v-if="$store.state.language === 'cn'">绿名单评审规则</p>
            <p class="font_tatle" v-else>Green List Criterion</p>
            <p class="font_tatle_e" v-if="$store.state.language === 'cn'">Green List Criterion</p>
          </div>
          <div class="bg_col" :style="{ backgroundColor: top == bg_colHeight * 3 ? '#5a822a' : '#92ab26' }"
            @click="bgtop(bg_colHeight * 3)">
            <p class="font_tatle" v-if="$store.state.language === 'cn'">黑名单评审规则</p>
            <p class="font_tatle" v-else>Black List Criterion</p>
            <p class="font_tatle_e" v-if="$store.state.language === 'cn'">Black List Criterion</p>
          </div>
        </div>
      </div>
    </div>
    <div class="fx_text" :style="'height: ' + innerHeight2 + 'px'">
      <div :style="'height: ' + innerHeight2 + 'px'" class="contents row swiper-no-swiping" v-if="top == 0"
        @mouseover="mouseover" @mouseout="mouseout">
        <vue-scroll :ops="ops">
          <div v-if="contents" class="swiper-no-swiping">
            <div class="content-title">
              <p>{{ contents.articleName }}</p>
              <p>{{ contents.summary }}</p>
            </div>
            <div class="vscroll" style="line-height: 2; " v-html="contents.content"></div>
          </div>
        </vue-scroll>
      </div>
      <div class="contents row swiper-no-swiping" v-if="top == bg_colHeight">
        <WhiteList></WhiteList>
      </div>
      <div class="contents row swiper-no-swiping" v-if="top == bg_colHeight * 2">
        <GreenList></GreenList>
      </div>
      <div class="contents row swiper-no-swiping" v-if="top == bg_colHeight * 3">
        <Blacklist></Blacklist>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/reuse/pageHeader'
import WhiteList from '@/components/greenProcurement/WhiteList'
import GreenList from '@/components/greenProcurement/GreenList'
import Blacklist from '@/components/greenProcurement/Blacklist'
import { articleDetail } from '@/api'
import '@/css/content.less'
import vuescrolljs from '@/mixin/index.js'





export default {
  mixins: [vuescrolljs],
  data() {
    return {
      innerHeight2: null,
      headerNmae: '<p style="color: #fff;">Green Procurement <br/>Guide</p>',
      headerNmaeCh: '<p style="color: #fff; margin-top: 42px">绿色采购指南</p>',
      flag: false,
      top: 0,
      TheText: '绿色采购指南',
      contents: {},
      bg_colHeight: 0
    }
  },
  async mounted() {
    const bg_col = await document.querySelector('.bg_col')

    this.bg_colHeight = bg_col.offsetHeight

    // window.onresize = () => {
    //   this.innerHeight2 = window.innerHeight
    //   // console.log(this.innerHeight2); 
    // }
    this.innerHeight2 = window.innerHeight
    window.addEventListener('resize', () => {
      this.innerHeight2 = window.innerHeight

      const bg_col = document.querySelector('.bg_col')
      this.bg_colHeight = bg_col.offsetHeight

    })
    // console.log(this.innerHeight2); 
    this.findS()
  },

  methods: {
    goNavto() {
      console.log('点击');
    },
    bgtop(val) {
      console.log(val);
      this.top = val
      switch (val) {
        case 0:
          this.TheText = '绿色采购指南'
          this.findS();
          break;
        case this.bg_colHeight:
          this.TheText = '白名单评审规则'
          break;
        case this.bg_colHeight * 2:
          this.TheText = '绿名单评审规则'
          break;
        case this.bg_colHeight * 3:
          this.TheText = '黑名单评审规则'
          break;
      }
    },
    findS() {
      const params = {
        articleId: 'WZNR201610311925050025',
        isEn: this.$store.state.language === 'en' ? 1 : 0
      }
      articleDetail(params).then((res) => {
        this.contents = res.data
      })
    },
    mouseover(e) {
      // console.log(e,'1332234');
      this.hub.$emit('mouseover1')
    },
    mouseout(e) {
      // console.log(e,'-------');
      this.hub.$emit('mouseout1')
    }
  },
  components: {
    PageHeader,
    WhiteList,
    GreenList,
    Blacklist
  }
}
</script>
<style lang="less" scoped>
.fx_1 {
  display: flex;
}

.sye_hdr {
  padding-top: 100px;
  height: 100%;

  flex: 4;
}

.width_1 {
  width: 468px;
  margin-top: 54px;
  color: #fff;

  p {
    font-size: 18px;
  }
}

.fx_text {
  padding-bottom: 20px;
  height: 100%;
  flex: 6;
  border-top: 5px solid #abd40598;
  border-bottom: 5px solid #abd40598;
  background-color: #fff;
}

.posa {
  height: 100%;

  position: relative;
}

.wefff {
  position: absolute;
  bottom: 74px;
  left: 308px;
  width: 300px;

  color: #fff;
  text-align: center;

  div:last-child {
    border: none;
  }

  .pos {
    height: 70px;
    line-height: 70px;
    position: absolute;
    left: -60px;
    top: 0px;

    i {
      font-size: 50px;
    }
  }

  .bg_col {
    background-color: #92ab26;
    padding-top: 20px;
    height: 70px;
    border-bottom: 1px solid #5a822a;
  }

  .font_tatle {
    font-size: 18px;
  }

  .font_tatle_e {
    font-size: 12px;
  }
}

.contents {
  padding: 20px 0 20px 30px;
  overflow: auto;

  .vscroll {
    margin-right: 30px;
  }
}

.row {
  width: 912px;
}

.content-title {
  margin: 10px 0px;

  p:nth-child(1) {
    font-size: 30px;
    font-weight: 700;
    color: #92ab26;
  }

  p:nth-child(2) {
    width: 96%;
    font-size: 18px;
    color: #747374;
    padding: 10px 0px 20px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
  }
}
</style>
