/*
 * @Description: 
 * @Author: wangying
 * @Date: 2021-09-26 10:48:48
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'Home',
  component: Home,
  meta: {
    flag: true,
    btmNav: true,
    flagNam: 0
  },
  // 加入绿链
  children: [
    {
    path: 'greenchain',
    name: 'GreenChain',
    redirect: '/greenchain/greenchainoperation',
    component: () => import('../views/greenchain.vue'),
    meta: {
      flag: false,
      btmNav: true
    },
    children: [
      {
        // 绿链行动
        path: 'greenchainoperation',
        name: 'GreenChainOperation',
        component: () => import('../views/greenchain/GreenChainOperation.vue'),
        meta: {
          flag: false,
          btmNav: true,
          flagNam: 1
        },
      },
      {
        // 行动宣言
        path: 'declarationOfAction',
        name: 'DeclarationOfAction',
        component: () => import('../views/greenchain/DeclarationOfAction.vue'),
        meta: {
          flag: false,
          btmNav: true,
          flagNam: 2
  
        },
      },
      {
        // 组织结构
        path: 'organizationStructure',
        name: 'OrganizationStructure',
        component: () => import('../views/greenchain/OrganizationStructure.vue'),
        meta: {
          flag: false,
          btmNav: true,
          flagNam: 3
  
        },
      },
      {
        // 企业名录
        path: 'memberDirectory',
        name: 'MemberDirectory',
        component: () => import('../views/greenchain/MemberDirectory.vue'),
        meta: {
          flag: false,
          btmNav: true,
          flagNam: 4
        },
      },
      {
        // 发展历程
        path: 'developmentHistory',
        name: 'DevelopmentHistory',
        component: () => import('../views/greenchain/DevelopmentHistory.vue'),
        meta: {
          flag: false,
          btmNav: true,
          flagNam: 5
        },
      },
      {
        // 企业名录
        path: '/greenchain/:subTypeName',
        name: 'content',
        component: () => import('../views/content/content.vue'),
        meta: {
          flag: false,
          btmNav: true,
          flagNam: 4
        },
      }
    ]
  },
  // 供应商名单
  {
    path: 'suppliers',
    name: 'Suppliers',
    redirect: '/suppliers/supplierswhite',
    component: () => import('../views/suppliers.vue'),
    meta: {
      flag: false,
      btmNav: true
    },
    children: [
      {
        // 白名单
        path: 'complianceSupplierswhite',
        name: 'complianceSupplierswhite',
        component: () => import('../views/suppliers/environmentalComplianceSupplierswhite.vue'),
        meta: {
          flag: false,
          btmNav: true,
          flagNam: 18

        },
      },
      {
        // 企业名录
        path: '/suppliers/:subTypeName',
        name: 'content',
        component: () => import('../views/content/content.vue'),
        meta: {
          flag: false,
          btmNav: true,
          flagNam: 4
        },
      },
      {
        // 白名单
        path: 'supplierswhite',
        name: 'SupplierSWhite',
        component: () => import('../views/suppliers/supplierswhite.vue'),
        meta: {
          flag: false,
          btmNav: true,
          flagNam: 6

        },
      },
      {
        // 绿名单
        path: 'greenlist',
        name: 'GreenList',
        component: () => import('../views/suppliers/greenlist.vue'),
        meta: {
          flag: false,
          btmNav: true,
          flagNam: 7

        },
      },
      {
        // 黑名单
        path: 'blacklist',
        name: 'BlackList',
        component: () => import('../views/suppliers/blacklist.vue'),
        meta: {
          flag: false,
          btmNav: true,
          flagNam: 8

        },
      },
      {
        // 商检测
        path: 'detection',
        name: 'Detection',
        component: () => import('../views/suppliers/detection.vue'),
        meta: {
          flag: false,
          btmNav: true,
          flagNam: 9

        },
      },
    ]
  },
  // 加入绿链
  {
    path: 'joingreen',
    name: 'JoinGreen',
    redirect: '/joingreen/guide',
    component: () => import('../views/joingreen.vue'),
    meta: {
      flag: false,
      btmNav: true
    },
    children: [{
      // 参与指南 
      path: 'guide',
      name: 'Guide',
      component: () => import('../views/joingreen/guide.vue'),
      meta: {
        flag: false,
        btmNav: true,
        flagNam: 10

      },
    },
    {
      // 企业名录
      path: '/joingreen/:subTypeName',
      name: 'content',
      component: () => import('../views/content/content.vue'),
      meta: {
        flag: false,
        btmNav: true,
        flagNam: 4
      },
    },
    {
      // 率名单申报
      path: 'declare',
      name: 'declare',
      component: () => import('../views/joingreen/declare.vue'),
      meta: {
        flag: false,
        btmNav: true,
        flagNam: 112
      },
    },
    {
      // 率名单申报
      path: 'whitelist',
      name: 'whitelist',
      component: () => import('../views/joingreen/whitelist.vue'),
      meta: {
        flag: false,
        btmNav: true,
        flagNam: 113
      },
    },
    {
      // 各品类联系人 
      path: 'thecontact',
      name: 'TheContact',
      component: () => import('../views/joingreen/TheContact.vue'),
      meta: {
        flag: false,
        btmNav: true,
        flagNam: 11
      },
    },

    ]
  },
  // 绿色企业案例
  {
    path: 'greenEnterpriseCase',
    name: 'greenEnterpriseCase',
    redirect: '/greenEnterpriseCase/supplierCase',
    component: () => import('../views/greenEnterpriseCase.vue'),
    meta: {
      flag: false,
      btmNav: true
    },
    children: [{
      // 供应商案例 
      path: 'supplierCase',
      name: 'supplierCase',
      component: () => import('../views/greenEnterpriseCase/supplierCase.vue'),
      meta: {
        flag: false,
        btmNav: true,
        flagNam: 71

      },
    },
    {
      // 企业名录
      path: '/greenEnterpriseCase/:subTypeName',
      name: 'content',
      component: () => import('../views/content/content.vue'),
      meta: {
        flag: false,
        btmNav: true,
        flagNam: 4
      },
    },
    {
      // 房企案例
      path: 'realEstateEnterprisesCase',
      name: 'realEstateEnterprisesCase',
      component: () => import('../views/greenEnterpriseCase/realEstateEnterprisesCase.vue'),
      meta: {
        flag: false,
        btmNav: true,
        flagNam: 72
      },
    }

    ]
  },
  // 新闻动态
  {
    path: 'news',
    name: 'News',
    redirect: '/news/newss',
    component: () => import('../views/news.vue'),
    meta: {
      flag: false,
      btmNav: true
    },
    children: [{
      path: 'newss',
      name: 'Newss',
      component: () => import('../views/news/newss.vue'),
      meta: {
        flag: false,
        btmNav: true,
        flagNam: 12
      },
    },{
      // 企业名录
      path: '/news/:subTypeName',
      name: 'content',
      component: () => import('../views/content/content.vue'),
      meta: {
        flag: false,
        btmNav: true,
        flagNam: 4
      },
    },]
  },
  // 栏目内容
  {
    path: '/content',
    name: 'content',
    component: () => import('../views/content.vue'),
    meta: {
      flag: false,
      btmNav: true
    },
    children: [{
      path: '/content/:typeName/:subTypeName',
      name: 'subContent',
      component: () => import('../views/content/content.vue'),
      meta: {
        flag: false,
        btmNav: true,
        flagNam: 12
      },
    },
    {
      path: '/content/:typeName',
      name: 'subType',
      component: () => import('../views/content/content.vue'),
      meta: {
        flag: false,
        btmNav: true,
        flagNam: 12
      },
    }
  ]
  },
  // 联系我们
  {
    path: 'contact',
    name: 'Contact',
    redirect: '/contact/contactGuide',
    component: () => import('../views/contact.vue'),
    meta: {
      flag: false,
      btmNav: true
    },
    children: [{
      path: 'contactGuide',
      name: 'ContactGuide',
      component: () => import('../views/contact/contactGuide.vue'),
      meta: {
        flag: false,
        btmNav: true,
        flagNam: 13
      },
    },{
      // 企业名录
      path: '/contact/:subTypeName',
      name: 'content',
      component: () => import('../views/content/content.vue'),
      meta: {
        flag: false,
        btmNav: true,
        flagNam: 4
      },
    }]
  },

  // 绿色采购标准
  {
    path: 'procurement',
    name: 'procurement',
    redirect: '/procurement/procurementGreen',
    component: () => import('../views/procurement.vue'),
    meta: {
      flag: false,
      btmNav: true
    },
    children: [{
      path: 'procurementGreen',
      name: 'procurementGreen',
      component: () => import('../views/procurement/procurementGreen.vue'),
      meta: {
        flag: false,
        btmNav: true,
        flagNam: 14
      },
    },
    // 白名单评审规则
    {
      path: 'procurementWhite',
      name: 'procurementWhite',
      component: () => import('../views/procurement/procurementWhite.vue'),
      meta: {
        flag: false,
        btmNav: true,
        flagNam: 15
      },
    },
    // 绿名单评审规则
    {
      path: 'procurementGreens',
      name: 'procurementGreens',
      component: () => import('../views/procurement/procurementGreens.vue'),
      meta: {
        flag: false,
        btmNav: true,
        flagNam: 16
      },
    },
    // 黑名单评审规则
    {
      path: 'procurementBlick',
      name: 'procurementBlack',
      component: () => import('../views/procurement/procurementBlick.vue'),
      meta: {
        flag: false,
        btmNav: true,
        flagNam: 17
      },
    },{
      // 企业名录
      path: '/procurement/:subTypeName',
      name: 'content',
      component: () => import('../views/content/content.vue'),
      meta: {
        flag: false,
        btmNav: true,
        flagNam: 4
      },
    }
    ]
  }

  ]
},
{
  path: '/about',
  name: 'About',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
}
]

const router = new VueRouter({
  routes
})

export default router
