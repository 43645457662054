<!--
 * @Description: 
 * @Author: wangying
 * @Date: 2021-09-27 19:04:39
-->
<template>
  <div class="pos" :style="'width: ' + innerW + 'px;'">
    <div class="swiper pos" id="swiper0122">
      <div class="swiper-wrapper pos">
        <div class="swiper-slide">
          <div class="main_by pos greenCategories_bg"
            :style="'background: url(' + CategoriesImgBg[CategoriesNum] + ')  no-repeat; '">
            <div class="greenCategories_bg1" v-if="num < 7"></div>
            <div class="greenCategories_bg2" v-if="num > 6"></div>
            <div class=" w_auto pos">
              <div class="sye_hdr" v-if="num < 7">
                <div v-if="num == 4">
                  <PageHeader :headerNmae="headerNmae4" :headerNmaeCh="headerNmaeCh4" :flag="flag"></PageHeader>
                </div>
                <div v-else-if="num == 6">
                  <PageHeader :headerNmae="headerNmae4" :headerNmaeCh="headerNmaeCh4" :flag="flag"></PageHeader>
                </div>
                <div v-else>
                  <PageHeader :headerNmae="headerNmae" :headerNmaeCh="headerNmaeCh" :flag="flag"></PageHeader>
                </div>
              </div>
              <div class="sye_hdr" v-if="num > 6">
                <div v-if="num == 8">
                  <PageHeader :headerNmae="headerNmae4" :headerNmaeCh="headerNmaeCh5" :flag="flag"></PageHeader>
                </div>
                <div v-else-if="num == 5 || num == 11">
                  <PageHeader :headerNmae="headerNmae4" :headerNmaeCh="headerNmaeCh5" :flag="flag"></PageHeader>
                </div>
                <div v-else>
                  <PageHeader :headerNmae="headerNmae_5" :headerNmaeCh="headerNmaeCh_5" :flag="flag"></PageHeader>
                </div>
              </div>
              <div class="fx_1">
                <p :style="{ color: num == 8 || num == 9 || num == 11 ? '#fff' : num == 12 ? '#554F4E' : '' }"
                  class="sy_font">
                  {{ num > 9 ? '' : 0 }}{{ num }}
                </p>
                <p :style="{
                  borderBottom:
                    num == 8 || num == 9 || num == 11 ? '2px dashed #fff' : num == 12 ? '2px dashed #554F4E' : ''
                }" class="sy_font_1"></p>
              </div>
              <div style="width: 50%;">
                <p :style="{ color: num == 6 ? '#fff' : num == 8 ? '#fff' : '' }" class="sy_font font_2"
                  v-html="$store.state.language === 'cn' ? tatleName.cn : tatleName.es"></p>
                <p v-if="$store.state.language === 'cn'" :style="{ color: num == 6 ? '#fff' : num == 8 ? '#fff' : '' }"
                  class=" font_3" v-html="tatleName.es">
                </p>
              </div>
              <div class="more fx_1 pointer" @click="hoves(num)">
                <p class=""  v-if="$store.state.language === 'cn'">评价标准</p>
                <p class=""  v-else>Criterion</p>
                &nbsp; &nbsp;
                <p class="iconRotate1">
                  <i class="iconfont icon-jiantou_qiehuanzuo_o"></i>
                </p>
              </div>
              <div class=" img_sy">
                <img :src="goodsImg[CategoriesNum]" alt="" />
              </div>
              <div class=" fx_sty">
                <div id="swiper_li">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide fx_1 pointer swiper-no-swiping">
                      <div v-for="(n) in 6" :key="n" :class="[num == n ? 'bg_1' : '', 'fx_syle line_h']"
                        @click="Categories(n)">
                        {{ $store.state.language === 'cn' ? tableMap[`${n}`].cn1 : tableMap[`${n}`].es1 }}
                      </div>

                    </div>
                    <div class="swiper-slide fx_1 pointer swiper-no-swiping">
                      <div v-for="(n) in 6" :key="n + 6" :class="[num == (n + 6) ? 'bg_1' : '', 'fx_syle line_h']"
                        @click="Categories(n + 6)">
                        {{ $store.state.language === 'cn' ? tableMap[`${n + 6}`].cn1 : tableMap[`${n + 6}`].es1 }}
                      </div>

                    </div>
                  </div>
                  <div class="swiper-button-prev"></div>
                  <!--左箭头。如果放置在swiper外面，需要自定义样式。-->
                  <div class="swiper-button-next"></div>
                  <!--右箭头。如果放置在swiper外面，需要自定义样式。-->
                </div>

              </div>
              <!-- 弹窗 -->
              <div class="dialog swiper-no-swiping" v-if="dialogVisible">
                <p class="circle-close pointer" @click="cancel">
                  <i class="el-icon-circle-close"></i>
                </p>
                <el-card class="box-card">
                  <GreenList :datas="datas" :tatleName="tatleName"></GreenList>
                </el-card>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="swiper-slide">
          <swiper></swiper>
        </div> -->
        <!-- <div class="swiper-slide">
          <swiper2></swiper2>
        </div> -->
      </div>

    </div>
    <div v-show="activeIndex == 1" class="swiper-button-prev-01 pointer swiper-no-swiping"><i
        class="iconfont icon-xiangzuojiantou"></i></div>
    <!--左箭头。如果放置在swiper外面，需要自定义样式。-->
    <div v-show="activeIndex == 0" class="swiper-button-next-01 pointer swiper-no-swiping"><i
        class="iconfont icon-xiangyoujiantou"></i></div>
    <!--右箭头。如果放置在swiper外面，需要自定义样式。-->

  </div>
</template>
<script>
import PageHeader from "@/components/reuse/pageHeader";
import GreenList from "@/components/greenCategories/GreenList";
// import swiper from "@/components/greenCategories/swiper";
// import swiper2 from "@/components/greenCategories/swiper2";
import { articleListByPage, articleDetail } from '@/api'
export default {
  components: {
    PageHeader,
    GreenList,
    // swiper,
    // swiper2
  },
  data() {
    return {
      mySwiper: {},
      activeIndex: 0,
      dialogVisible: false,
      headerNmae: '<p style="color: #1c4500;">Green Supply Chain Action <br> White List Criterion</p>',
      headerNmaeCh: '<p style="color: #92ab26;">绿色采购品类标准 — 白名单</p>',
      headerNmae4: '<p style="color: #fff;">Green Supply Chain Action <br>  White List Criterion</p>',
      headerNmaeCh4: '<p style="color: #fff;">绿色采购品类标准 — 白名单</p>',
      headerNmaeCh5: '<p style="color: #fff;">绿色采购品类标准 — 白名单</p>',
      headerNmaeCh_5: '<p style="color: #231815;">绿色采购品类标准 — 白名单</p>',
      headerNmae_5: '<p style="color: #231815;">Green Supply Chain Action <br>  White List Criterion</p>',


      flag: false,
      num: 1,
      tatleName: {
        cn: "铝合金无铬钝化控制",
        cn1: "铝合金无铬钝化控制",
        es: `Control of chromium free passivation <br/>of aluminum alloy`,
        es1: `Control of chromium free passivation of aluminum alloy`
      },
      datas: {},
      innerH: '',
      innerW: '',
      pages: {
        page: 1,
        limit: 10
      },
      CategoriesNum: 0,
      CategoriesImgBg: [
        require('@/assets/Categories_bg_06.jpg'),
        require('@/assets/Categories_bg_05.jpg'),
        require('@/assets/Categories_bg_04.jpg'),
        require('@/assets/Categories_bg_03.jpg'),
        require('@/assets/Categories_bg_02.jpg'),
        require('@/assets/Categories_bg_01.jpg'),
        // -----
        require('@/assets/Categories_bg_07.jpg'),
        require('@/assets/Categories_bg_08.jpg'),
        require('@/assets/Categories_bg_09.jpg'),
        require('@/assets/Categories_bg_10.jpg'),
        require('@/assets/Categories_bg_11.jpg'),
        require('@/assets/Categories_bg_12.jpg'),
      ],
      // require('@/assets/goods0' + num + '.jpg')

      goodsImg: [
        require('@/assets/goods06.jpg'),
        require('@/assets/goods05.jpg'),
        require('@/assets/goods04.jpg'),
        require('@/assets/goods03.jpg'),
        require('@/assets/goods02.jpg'),
        require('@/assets/goods01.jpg'),
        //  ----
        require('@/assets/goods07.jpg'),
        require('@/assets/goods08.jpg'),
        require('@/assets/goods09.jpg'),
        require('@/assets/goods10.jpg'),
        require('@/assets/goods11.jpg'),
        require('@/assets/goods12.jpg'),
      ],

      tableMap: {
        '12': {
          cn: "石木塑（SPC石晶）墙地材 <br>  ",
          cn1: "石木塑（SPC石晶）墙地材 ",
          es: ` Stone Wood Plastic Wall and Floor Materials`,
          es1: ` Stone Wood Plastic Wall and Floor Materials`,
          articleId: "WZNR202106041524210036"
        },
        '11': {
          cn: "岩棉品质与环保控制 <br>  ",
          cn1: "岩棉品质与环保控制  ",
          es: `Quality and Environmental Protection Control of Rock Wool Quality`,
          es1: `Quality and Environmental Protection Control of Rock Wool Quality`,
          articleId: "WZNR202106041436230033"
        },
        '10': {
          cn: "空气源热泵品质与环保控制 <br>  ",
          cn1: "空气源热泵品质与环保控制  ",
          es: `Quality and Environmental Protection Control of <br> Air Source Heat Pump`,
          es1: `Quality and Environmental Protection Control of Air Source Heat Pump`,
          articleId: "WZNR202106041442350034"
        },
        '9': {
          cn: "无铬钝化轻钢龙骨 <br>  ",
          cn1: "无铬钝化轻钢龙骨  ",
          es: `Chrome-free Passivated Light Steel Keel`,
          es1: `Chrome-free Passivated Light Steel Keel`,
          articleId: "WZNR202010281203310023"
        },
        '8': {
          cn: "LED光源健康与环保控制 <br>  ",
          cn1: "LED光源健康与环保控制 ",
          es:
            "LED Light Source Health and Environmental Protection Control",
          es1:
            "LED Light Source Health and Environmental Protection Control",
          articleId: "WZNR202010281233050024"
        },
        '7': {
          cn:
            '<p>门窗节能控制 <br>  </p>',
          cn1:
            '门窗节能控制  ',
          es:
            '<p>Energy Saving Control of Doors and Windows</p>',
          es1:
            'Energy Saving Control of Doors and Windows',
          articleId: "WZNR202010281244130025"
        },
        '6': {
          cn:
            '<p>石材产品清洁生产</p>',
          cn1:
            '石材产品清洁生产',
          es:
            '<p>Clean production of stone products</p>',
          es1:
            'Clean production of stone products',
          articleId: "WZNR201807091655440022",
        },
        '5': {
          cn: "水性涂料烷基酚聚氧乙烯醚（APEO）控制",
          cn1: "水性涂料烷基酚聚氧乙烯醚（APEO）控制",
          es:
            "Waterborne coating alkyl phenol polyoxyethylene <br/>Ethylene ether (APEO) control",
          es1:
            "Waterborne coating alkyl phenol polyoxyethylene Ethylene ether (APEO) control",
          articleId: "WZNR201908190434580077"
        },
        '4': {
          cn: "聚苯乙烯类保温材料<br/>HBCD阻燃剂有害性控制",
          cn1: "聚苯乙烯类保温材料HBCD阻燃剂有害性控制",
          es: `Polystyrene thermal insulation material<br/>
Harmful control of HBCD flame retardant`,
          es1: `Polystyrene thermal insulation material Harmful control of HBCD flame retardant`,
          articleId: "WZNR201908190447200078"
        },
        '3': {
          cn: "室内装饰人造板及其制品甲醛控制",
          cn1: "室内装饰人造板及其制品甲醛控制",
          es: `Interior decorative wood-based panel<br/>
                    Formaldehyde control of and its products`,
          es1: `Interior decorative wood-based panel Formaldehyde control of and its products`,
          articleId: "WZNR202106041514120035"
        },
        '2': {
          cn: "木材来源合法化",
          cn1: "木材来源合法化",
          es: "Legalization of wood sources",
          es1: "Legalization of wood sources",
          articleId: "000000061"
        },
        '1': {
          cn: "铝合金无铬钝化控制",
          cn1: "铝合金无铬钝化控制",
          es: `Control of chromium free passivation <br/>of aluminum alloy`,
          es1: `Control of chromium free passivation of aluminum alloy`,
          articleId: "WZNR202106041416290032"
        }
      }

    };
  },
  mounted() {
    const _this = this
    this.mySwiper = new Swiper('#swiper0122', {
      // loop : true,
      autoplay: {
        delay: 30000,//1秒切换一次
        disableOnInteraction: true,
      },
      //  simulateTouch : false,
      //  allowTouchMove: false,
      // allowTouchMove: false,
      on: {
        slideChangeTransitionStart: function () {

          console.log(this.activeIndex);
          _this.activeIndex = this.activeIndex
        },
      },

      navigation: {
        nextEl: '.swiper-button-next-01',
        prevEl: '.swiper-button-prev-01',
      },
    })
    var mySwiper = new Swiper('#swiper_li', {
      autoplay: false,//可选选项，自动滑动
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })

    this.innerH = window.innerHeight
    this.innerW = window.innerWidth
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 1280) {
        this.innerW = 1280
      } else {
        this.innerW = window.innerWidth
      }

    })
    // console.log(window.innerHeight, window.innerWidth,);
    // console.dir(main_by)
    this.articleListByPage()
    const params = {
      articleId: "WZNR202106041416290032",
      isEn: this.$store.state.language === 'en' ? 1 : 0
    }
    articleDetail(params).then((res) => {
      this.datas = res.data
    })

  },
  methods: {
    articleListByPage() {
      // console.log(this.dateY,' =====____');
      const params = {
        ...this.pages,
        catCode: "000003%7C000015",
        year: this.dateY,
        isEn: this.$store.state.language === 'en' ? 1 : 0
      }
      articleListByPage(params).then((res) => {
        // console.log(res.data);
        this.list = res.data.list
        this.total = res.data.total

      })
    },
    hoves() {
      // console.log(123);
      const params = {
        articleId: this.tatleName.articleId,
        isEn: this.$store.state.language === 'en' ? 1 : 0
      }
      articleDetail(params).then((res) => {
        this.datas = res.data
        this.dialogVisible = true;
      })
    },
    cancel() {
      this.dialogVisible = false;
      // this.datas = {}
    },
    Categories(val) {
      // console.log('点击', val);
      this.CategoriesNum = val - 1
      // this.$emit("CategoriesBg", val);
      this.num = val;
      this.tatleName = this.tableMap[`${val}`];
      const params = {
        articleId: this.tatleName.articleId,
        isEn: this.$store.state.language === 'en' ? 1 : 0
      }
      articleDetail(params).then((res) => {
        // console.log(res);
        this.datas = res.data
        // this.list = [res.data]
        // this.content = 
      })
    }
  },

};
</script>
<style lang="less" scoped>
#swiper_li {
  --swiper-theme-color: #ff6600;
  /* 设置Swiper风格 */
  --swiper-navigation-color: #666;
  /* 单独设置按钮颜色 */
  --swiper-navigation-size: 30px;
  /* 设置按钮大小 */
}

.main_by2 {
  background: url("../assets/Categories_bg_07.jpg");
  background-size: 100% 100%;
  min-width: 1280px;
}

.greenCategories_bg {
  position: relative;
  height: 100%;
  min-width: 1280px;

  /* height: 948px; */
  background: url("../assets/Categories_bg_05.jpg") no-repeat;
  /* background-blend-mode: multiply; */
  background-size: 100% 100% !important;
}

.greenCategories_bg1 {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #ffffff8c;
}

.greenCategories_bg2 {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #4a4a4a38;
}

.pos {
  position: relative;
  height: 100%;
}

.sye_hdr {
  padding-top: 80px;
}

.fx_1 {
  display: flex;
}

.sy_font {
  margin-top: 120px;
  margin-right: 30px;
  font-size: 80px;
  font-weight: 700;
  color: #92ab26;
}

.sy_font_1 {
  width: 686px;
  position: relative;
  top: -48px;
  border-bottom: 3px dashed #92ab26;
}

.font_2 {
  color: #231815;
  margin-top: 40px;
  font-size: 40px;
}

.font_3 {
  font-size: 20px;
  font-weight: 700;
  color: #494645;
}

.more {
  position: absolute;
  bottom: 24%;
  margin-top: 50px;
  justify-content: center;
  width: 160px;
  height: 36px;
  line-height: 34px;
  text-align: center;
  color: #fff;
  border: 1px solid #92ab26;
  background: #92ab26;
  font-size: 18px;

  .iconRotate1 {
    line-height: 36px;
    display: inline-block;

    color: #fff;
    transform: rotate(180deg);
    transform: rotateY(180deg);

    i {
      font-size: 30px;
    }
  }
}

.img_sy {
  width: 758px;
  height: 455px;
  position: absolute;
  top: 240px;
  left: 763px;

  img {
    width: 100%;
    height: 100%;
  }
}

.fx_sty {
  position: absolute;
  bottom: 10%;
  width: 100%;
  margin-top: 100px;
  background-color: #fff;
  overflow: hidden;
}

.fx_syle {
  height: 78px;
  flex: 1;
  text-align: center;
  font-size: 16px;
  border-right: 1px solid #92ab26;

  &:last-child {
    border-right: none;
  }
}

.line_h {
  line-height: 78px;
}

.line_1 {
  line-height: 78px;
  background: #92ab26;
  color: #fff;
}

.line_h_1 {
  padding: 20px 10px 0 10px;
}

.bg_1 {
  position: relative;
  background-color: #92ab26;

  &::after {
    content: "";
    width: 8px;
    height: 8px;
    display: inline-block;
    background: #92ab26;
    position: absolute;
    top: -5%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform: rotate(45deg);
  }
}

.dialog {
  position: absolute;
  z-index: 2;
  width: 80%;
  height: 800px;
  top: 10%;
  left: 50%;
  transform: translate(-50%, 0);
}

.circle-close {
  position: absolute;
  top: -48px;
  right: -52px;
  color: #fff;
  font-size: 40px;
}

.swiper-button-prev-01 {
  position: absolute;
  // width: 100px;
  // height: 100px;
  border-radius: 8px;
  top: 45%;
  transform: translateY(-50%);
  // background: red;
  z-index: 10;

  i {
    font-size: 60px;
  }
}

.swiper-button-next-01 {
  position: absolute;
  // width: 80px;
  // height: 80px;
  // line-height: 80px;
  // background: #1211112e;
  border-radius: 8px;
  text-align: right;
  top: 45%;
  right: 0;
  transform: translateY(-50%);
  // background: red;
  z-index: 10;

  i {
    font-size: 60px;
  }
}

.swiper-button-next-01:hover {
  background: #1211112e;
}

.swiper-button-prev-01:hover {
  background: #1211112e;
}

.swiper-button-next,
.swiper-button-prev {
  width: 40px;
  height: 40px;
  margin-top: -18px;
}

.swiper-button-next:hover {
  width: 40px;
  height: 40px;
  background: #1211112e;
  border-radius: 8px;
}

.swiper-button-prev:hover {
  width: 40px;
  background: #1211112e;
  border-radius: 8px;
}
</style>
