<!--
 * @Description: 
 * @Author: wangying
 * @Date: 2021-09-28 10:32:43
-->
<template>
  <div class="by ptn">
    <div class="sye_hdr">
      <PageHeader :headerNmae='headerNmae' :headerNmaeCh='headerNmaeCh' :flag='flag'></PageHeader>
    </div>
    <div class="more fx_1 pointer" @click="goto">
      <p class=" " v-if="$store.state.language === 'cn'">查看详情 </p>
      <p class=" " v-else>View Details </p>
      &nbsp; &nbsp;
      <p class="iconRotate1"><i class="iconfont icon-jiantou_qiehuanzuo_o"></i></p>
    </div>
    <!-- 轮播图 -->
    <div class="swiper_1">
      <div @mousemove="add">
        <div id="swiper1">
          <div class="swiper-wrapper">
            <div v-for="(item, index) in imgs" :key="index" @click="supply(item.id)" class="ptn swiper-slide">
              <img class="imgs" :src="item.url" alt="">
              <div class="ptnabt">
                <h3 v-if="$store.state.language === 'cn'">{{ item.tatle }}</h3>
                <h3 v-else>{{ item.tatleEs }}</h3>
                <h5 v-if="$store.state.language === 'cn'" class="hds">{{ item.tatleEs }}</h5>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
    <!-- <div class=" swiper_button">
      <div @click="add" class="swiper-button-prev"></div>
      <div @click="add" class="swiper-button-next"></div>
    </div>
    <div class=" hr">
      <div class="hrNum">0{{addNum + 1}}</div>
    </div> -->
  </div>
</template>
<script>
import PageHeader from '@/components/reuse/pageHeader'
import { mapActions, mapGetters } from 'vuex'



export default {
  data() {
    return {
      headerNmae: `<p style="color: #1c4500;">Green Procurement List </p>`,
      headerNmaeCh: '<p style="color: #92ab26;">绿色采购名单</p>',
      flag: false,
      mySwiper: null,
      addNum: 0,
      imgs: [{
        id: 1,
        url: require('@/assets/aupply_01.jpg'),
        tatle: '供应商白名单',
        tatleEs: 'White List'
      }, {
        id: 2,
        url: require('@/assets/aupply_02.jpg'),
        tatle: '供应商绿名单',
        tatleEs: 'Green List'
      }, {
        id: 3,
        url: require('@/assets/aupply_03.jpg'),
        tatle: '供应商黑名单',
        tatleEs: 'Black List'
      }, {
        id: 4,
        url: require('@/assets/aupply_04.jpg'),
        tatle: '供应商检测结果公示',
        tatleEs: 'Supplier Monitoring Results Publicity'
      },]

    }
  },
  mounted() {
    this.mySwiper = new Swiper('#swiper1', {
      autoplay: true,//可选选项，自动滑动
      // loop: true,
      slidesPerView: 4,
      spaceBetween: 30,
      centeredSlides: false,

      // navigation: {
      //   nextEl: '.swiper-button-next',
      //   prevEl: '.swiper-button-prev',
      // },
      // pagination: {
      //   el: '.swiper-pagination',
      // },
      on: {
        // click: function () {
        //   console.log(this.clickedIndex);
        // }
      },
      slideChangeTransitionEnd: function (index) {
        console.log(index);
      },
    })
  },
  computed: {
    ...mapGetters(['typeLists'])
  },
  methods: {
    ...mapActions(['changeType', 'changeSubType']),
    add() {
      setTimeout(() => {
        this.addNum = this.mySwiper.realIndex
      }, 200)
    },
    goto() {
      this.changeType(this.typeLists[3]);
      this.changeSubType(this.typeLists[3].subMap[0]);
      this.$router.push({ name: 'SupplierSWhite' })
    },
    supply(id) {
      this.changeType(this.typeLists[3]);
      switch (id) {
        case 1:
        this.changeSubType(this.typeLists[3].subMap[1]);
          this.$router.push({ name: 'SupplierSWhite' })
          break;
        case 2:
        this.changeSubType(this.typeLists[3].subMap[2]);
          this.$router.push({ name: 'GreenList' })
          break;
        case 3:
        this.changeSubType(this.typeLists[3].subMap[3]);
          this.$router.push({ name: 'BlackList' })
          break;
        case 4:
        this.changeSubType(this.typeLists[3].subMap[4]);
          this.$router.push({ name: 'Detection' })
          break;


      }
    }
  },
  components: {
    PageHeader,

  }
}
</script>
<style lang="less" scoped>
.by {
  padding-top: 100px;
  height: 100%;
}

.ptn {
  position: relative;
}

.fx_1 {
  display: flex;
}

.more {
  position: absolute;
  bottom: 20%;
  margin-top: 50px;
  justify-content: center;
  width: 176px;
  height: 46px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  border: 1px solid #92ab26;
  background: #92ab26;
  font-size: 24px;

  .iconRotate1 {
    display: inline-block;
    line-height: 46px;
    color: #fff;
    transform: rotate(180deg);
    transform: rotateY(180deg);

    i {
      font-size: 30px;
    }
  }
}

.swiper_1 {
  width: 1200px;
  height: 508px;
  overflow: hidden;
  position: absolute;
  top: 268px;
  right: 0;
}

.swiper-container {
  height: 508px;
}

.swiper_button {
  position: absolute;
  bottom: 90px;
  right: 956px;
  z-index: 200;
  width: 160px;
  height: 100px;
  color: #92ab26;
  --swiper-theme-color: #ff6600;
  /* 设置Swiper风格 */
  --swiper-navigation-color: #92ab26;
  /* 单独设置按钮颜色 */
  --swiper-navigation-size: 20px;

  /* 设置按钮大小 */
  .swiper-button-prev,
  .swiper-button-next {
    width: 60px;
    height: 60px;
    border: 2px solid #92ab26;
    border-radius: 50%;
  }
}

.hr {
  width: 755px;
  position: absolute;
  right: 179px;
  bottom: 120px;
  border-top: 2px solid #92ab26;

  .hrNum {
    position: absolute;
    right: -80px;
    top: -30px;
    font-size: 50px;
    font-weight: 700;
    color: #92ab26;
  }
}

.imgs {
  width: 100%;
}

.ptnabt {
  padding: 20px;
  position: absolute;
  bottom: 10px;
  color: #fff;
  font-size: 18px;

  h3 {
    margin-bottom: 10px;
  }

  .hds {
    height: 40px;
  }
}
</style>
