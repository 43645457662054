/*
 * @Description: 
 * @Author: wangying
 * @Date: 2021-10-19 20:34:53
 */
// 滚动条
import vuescrolljs from '@/utils/vuescroll.js'
export default {
  data: function () {
    return {
      ...vuescrolljs,
      message: 'hello',
      foo: 'abc'
    }
  }
}
