/*
 * @Description: 
 * @Author: wangying
 * @Date: 2021-10-26 14:44:49
 */


import { Axios } from "../Axios";

//分页查询文章接口
export const articleListByPage = async params => {

  return await Axios(`base/channelArticleApi/api/getFrontChannelArticleListPageApi?channelId=lvlian&page=${params.page}&limit=${params.limit}&year=${params.year || ''}&catCode=${params.catCode}&isEn=${params.isEn}`);
};

//分页查询文章接口
export const articleDetail = async params => {
  
  return await Axios(`base/channelArticleApi/api/getFrontChannelArticleDetailApi?articleId=${params.articleId}&isEn=${params.isEn}`);
};
// 获取所有一级分类
export const frontChannelOneLevelArticleType = async params => {
  return await Axios(`base/channelArticleApi/api/getFrontChannelOneLevelArticleTypeApi?channelId=lvlian`);
}

export const getFrontChannelArticleTypeSubApi = async params => {
  return await Axios(`base/channelArticleApi/api/getFrontChannelArticleTypeSubApi?typeId=${params.typeId}`);
}