<!--
 * @Description: 
 * @Author: wangying
 * @Date: 2021-10-08 10:21:49
-->
<template>
  <div class="sty">
    <div class="title">
      <ListTitle :headerNmae="tatleName.es1" :headerNmaeCh="tatleName.cn1"></ListTitle>
    </div>
    <p class="hr_1"></p>
    <div>

      <div class="ofw" @mouseover="mouseover" @mouseout="mouseout">
        <vue-scroll :ops="ops">
          <div v-for="(item,index) in list" :key="index" class="greenbr">
            <div class="fx htd" @click="add">
              <div class="date ">
                <p class="ys">{{dateFormat(datas.addTime, 'MM-dd')}}</p>
                <p class="ys1">{{dateFormat(datas.addTime, 'yyyy')}}</p>
                <p class="brder"></p>
              </div>
              <div class="row">{{tatleName.cn1}}</div>
              <div class="row1">{{tatleName.es1}}</div>
              <!-- <div class="adds">+</div> -->
            </div>
          </div>
          <div class="" style="line-height: 2;" v-html="datas.content || '无内容'"></div>
        </vue-scroll>
      </div>
    </div>
  </div>
</template>
<script>
import vuescrolljs from '@/mixin/index.js'
import ListTitle from '@/components/reuse/listTitle'
import dateFormat from '@/utils/time'

export default {
  mixins: [vuescrolljs],
  props: {
    tatleName: {
      type: Object,
      default: () => {
        return {
          cn1: '石材产品清洁生产',
          es1: 'Clean production of stone products'
        }
      }
    },
    datas: {
      type: Object,
    }
  },
  data() {
    return {
      dateFormat,
      headerNmaeCh: '<p style="color: #92ab26;">绿色供应链绿名单准则</p>',
      headerNmae: '<p style="color: #747374;">Green supply chain green List review rules</p>',
      list: [1],
      flag: false
    }
  },
  methods: {
    add() {
      this.list = [1]
      this.flag = true
    },
    mouseover(e) {
      // console.log(e,'1332234');
      this.hub.$emit('mouseover1')
    },
    mouseout(e) {
      // console.log(e,'-------');
      this.hub.$emit('mouseout1')
    }
  },
  components: {
    ListTitle
  }
}
</script>
<style lang="less" scoped>
.sty {
  padding: 55px 10px 10px 60px;
}
.hr_1 {
  margin-top: 34px;
  margin-right: 40px;
  margin-bottom: 10px;
  border-top: 2px solid #92ab26;
}
.fx {
  display: flex;
}
.htd {
  height: 60px;
  border-bottom: 1px solid #b4c56a;
  background: #57802b;
}
.greenbr {
  // width: 758px;
  height: 74px;
  margin-bottom: 14px;
  margin-right: 40px;
  border-top: 2px solid #c3ce00;
  border-bottom: 1px solid #c3ce00;
}
.date {
  width: 100px;
  padding-left: 20px;
  position: relative;
  .ys {
    margin-top: 8px;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
  }
  .ys1 {
    margin-top: -2px;
    color: #fff;
    font-size: 14px;
  }
  .brder {
    height: 40px;
    position: absolute;
    top: 10px;
    right: 0;
    border-right: 1px solid #a1b60d;
  }
}
.row {
  width: 224px;
  padding-left: 15px;
  line-height: 60px;
  font-size: 18px;
  color: #f6f8f3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.row1 {
  flex: 1;
  padding-right: 50px;
  padding-left: 10px;
  font-size: 14px;
  color: #dee6d6;
  position: relative;
  top: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.adds {
  width: 20px;
  height: 20px;
  line-height: 15px;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 50%;
  color: #fff;
  position: relative;
  top: 20px;
  left: -8px;
}
.ofw {
  height: 580px;
  overflow: auto;
}
</style>