<!--
 * @Description: 
 * @Author: wangying
 * @Date: 2021-09-27 13:50:06
-->
<template>
  <div class="fx">
    <div>
      <p class="font_si Impact" v-html="headerNmae"></p>
      <p class="font_sy Impact" v-if="$store.state.language === 'cn'" v-html="headerNmaeCh"></p>
    </div>
    <!-- falg 右边栏显示隐藏 -->
    <a v-if="flag" @click="goNavto" href="javascript:;">
      <div class="se_ioc">{{ goNav }} <p><i class="iconfont icon-jiantou_qiehuanzuo"></i></p>
      </div>
    </a>
  </div>
</template>
<script>
export default {
  props: ['headerNmae', 'headerNmaeCh', 'goNav', 'flag'],
  methods: {
    goNavto() {
      this.$emit('goNavto')
    }
  }

}
</script>
<style lang="less" scoped>
.fx {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.font_si {
  font-size: 50px;
  font-weight: 700;
  color: #5a6f18;
  // margin-bottom: 10px;
}

.font_sy {
  font-size: 30px;
  color: #92ab26;
}

.se_ioc {
  font-size: 18px;
  width: 200px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  color: #fff;
  background-color: #5a6f18;

  p {
    display: inline-block;
    transform: rotate(180deg);
    transform: rotateY(180deg);
  }
}</style>