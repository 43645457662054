<!--
 * @Description: 
 * @Author: wangying
 * @Date: 2021-09-26 10:48:48
-->
<template>
  <div id="app" :style="'height:'+innerHeight1+ 'px;width: '+innerW+'px;'">
    <vue-scroll ref="vs" :ops="ops">
      <router-view />
    </vue-scroll>
  </div>
</template>
<script>
import vuescrolljs from '@/mixin/index.js'

export default {
  mixins: [vuescrolljs],
  data() {
    return {
      innerHeight1: null,
      innerW: null,
    }
  },
  mounted() {


    // const app = document.querySelector('.__vuescroll')
    // setTimeout(() => {
    //   app.scrollTo(0, 1000)
    // }, 1000);
    // console.dir(app)
    this.hub.$on('scrollToz', () => {
      this.$refs["vs"].scrollTo(
        {
          y: this.innerHeight1 + 60
        },
        700
      );
    })
    this.innerHeight1 = window.innerHeight
    this.innerW = window.innerWidth
    // window.onresize = () => {
    //   this.innerHeight1 = window.innerHeight
    // }
    window.addEventListener('resize', () => {
      this.innerHeight1 = window.innerHeight

      if (window.innerWidth <= 1280) {
        const html = document.querySelector('html')
        // console.log(html);
        html.style.fontSize = '128px'
        console.dir( html.style);
        this.innerW = 1280
         this.innerHeight1 -= 20
      } else {
        this.innerW = window.innerWidth
      }
      // console.log(this.innerW);
      // console.log(this.innerW <= 1200);
    })
  },
  methods: {
  }
}
</script>
<style>
/* @import url('http://at.alicdn.com/t/font_2837574_3h3ewtmo65f.css'); */
/* @import url('https://unpkg.com/swiper/swiper-bundle.css'); */
#app {
  overflow: auto;
  scrollbar-color: transparent transparent;
  scrollbar-track-color: transparent;
  scrollbar-width: 0px;
  -moz-scrollbar-track-color: transparent;
}
</style>
