<!--
 * @Description: 
 * @Author: wangying
 * @Date: 2021-09-27 11:29:46
-->
<template>
  <div class="latestnews">
    <div class="bodyk">
      <div class="sye_hdr">
        <PageHeader :headerNmae="headerNmae" :headerNmaeCh="headerNmaeCh" :goNav="$store.state.language === 'cn' ? goNav : goNavEng" @goNavto="goNavto" :flag="flag">
        </PageHeader>
      </div>
      <div class="fx ">
        <div class=" pl20">
          <div class=" stye_1">
            <video class="video_ys" src="/绿链介绍动画2021-10-28.m4v" controls="controls">
              您的浏览器不支持 video 标签。
            </video>
          </div>
          <!-- <div class=" stye_2"> -->
          <div class="mt10 stye_1">
            <video class=" video_ys" muted src="/绿链五周年采访视频.mp4" controls="controls">
              您的浏览器不支持 video 标签。
            </video>
          </div>
          <!-- </div> -->
        </div>
        <div class="fx_1 con_bg">
          <div class="fx_js colfff">
            <div>新闻中心</div>
            <div>2021-08-07</div>
          </div>
          <div class="tatle">
            <p class="font_sy">“绿名单”申报开启</p>
            <p class="font_sy">欢迎报名成为房地产绿链行动</p>
            <p class="font_sy1">
              当前，“绿名单”项目的评价标准已完成，“绿名单”申报入口已在绿链官网上线，欢迎各企业踊跃申报！依据“绿名单”标准，在资源、能源、环境等几方面表现优秀的供应链企业将通过筛选后进入“绿名单”，这些供应商将会得到房地产企业的优先采购。
            </p>
            <P class="font_sy2 pointer " @click="goNavto()">了解更多<i class="ftsz iconfont icon-anniu-jiantouxiangyou_o"></i>
            </P>
          </div>
        </div>
        <div class="fx_1 fx_2">
          <div v-for="item in list" :key="item.articleId" @click="goNavto(item.articleId)" class="flex_1 pointer ">
            <div class="img_1">
              <img :src="item.imgUrl || require('@/assets/logo1.png')" alt="" />
            </div>
            <div class="flex_1 text_1">
              <div class="tatle_2">
                {{
                  item.articleName.length > 25
                  ? item.articleName.slice(0, 22) + "..."
                  : item.articleName
                }}
              </div>
              <div class="flex_1 date_1">
                <div>{{ dateFormat(item.createdat, "yyyy-MM-dd") }}</div>
                <div>
                  <p class="iconRotate1">
                    <i class="width_w iconfont icon-jiantou_qiehuanzuo_o"></i>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageHeader from "@/components/reuse/pageHeader";
import { articleListByPage } from "@/api";
import dateFormat from "@/utils/time";
export default {
  data() {
    return {
      dateFormat,
      headerNmae: "Latest News",
      headerNmaeCh: "最新动态",
      goNav: "更多新闻",
      goNavEng: "More News",
      videoUrl: '/绿链介绍动画2021-10-28.m4v',
      flag: true,
      list: [],
      pages: {
        page: 1,
        limit: 3
      }
    };
  },
  mounted() {
    this.articleListByPage();
    let video_ys = document.querySelector('.video_ys')
    // video_ys.muted = true
    // video_ys.autoplay = true

    // console.dir(video_ys.requestFullscreen());
    console.dir(video_ys);
    let val = 0
    // setInterval(() => {
    //   if (val == 0) {
    //     val = 1
    //     this.videoUrl = '/绿链五周年采访视频.mp4'
    //     let video_ys = document.querySelector('.video_ys')
    //     video_ys.muted = true
    //     video_ys.autoplay = true
    //   } else {
    //     val = 0
    //     this.videoUrl = '/绿链介绍动画2021-10-28.m4v'
    //     let video_ys = document.querySelector('.video_ys')
    //     video_ys.muted = true
    //     video_ys.autoplay = true
    //   }
    // }, (1000 * 60 * 30));
  },
  methods: {
    goNavto(id) {
      if (id) {
        this.$router.push({
          name: "News",
          query: {
            id: id
          }
        });
      } else {
        this.$router.push({ name: "News" });
      }
    },
    articleListByPage() {
      // console.log(this.dateY,' =====____');
      const params = {
        ...this.pages,
        catCode: "000002%7C000012",
        isEn: this.$store.state.language === 'en' ? 1 : 0
      };
      articleListByPage(params).then(res => {
        console.log(res.data);
        this.list = res.data.list;
      });
    }
  },
  components: {
    PageHeader
  }
};
</script>
<style lang="less" scoped>
.latestnews {
  position: relative;
  height: 100%;
}

.bodyk {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.sye_hdr {
  // padding-top: 20px;
  margin-bottom: 8%;
}

.fx {
  display: flex;
  justify-content: space-between;
}

.fx_1 {
  flex: 1;

}

.stye_1 {
  width: 400px;
  height: 248px;

  // transform: scale(0.9)
}

.stye_2 {
  margin-top: 10px;
  width: 488px;
  height: 216px;
  background-color: #fff;
}

.video_ys {
  width: 420px;
  height: 100%;
  // width: 94%;
}

.fx_js {
  padding: 34px;
  display: flex;

  justify-content: space-between;

  div {
    color: #b2b2b3;
    font-size: 16px;
    font-weight: 600;
  }
}

.fx_js_1 {
  padding: 0 36px;
  font-size: 22px;
  font-weight: 700;
}

.iconRotate {
  display: inline-block;
  position: relative;
  left: 392px;
  top: 38px;
  color: #92ab26;
  transform: rotate(180deg);
  transform: rotateY(180deg);
}

.con_bg {
  background-color: #92ab26;

  .colfff {
    div {
      color: #fff;
    }
  }
}

.tatle {
  padding: 0 34px;
  color: #fff;
}

.pl20 {
  width: 460px;
  padding-left: 20px;
}

.font_sy {
  font-size: 30px;
  font-weight: 700;
}

.font_sy1 {
  font-size: 16px;
  margin-top: 50px;
}

.font_sy2 {
  margin-top: 100px;
  font-weight: 700;
  cursor: pointer;
  font-size: 18px;

  .ftsz {
    position: relative;
    top: 2px;
    font-size: 26px;
  }
}

.flex_1 {
  display: flex;
  margin-bottom: 23px;

  &:nth-child(3) {
    margin-bottom: 0px;
    height: 121px;
  }
}

.img_1 {
  margin-top: 0px;
  width: 384px;

  img {
    width: 236px;
    height: 144px;
  }
}

.fx_2 {
  padding-left: 20px;
}

.width_w {
  font-size: 28px !important;
  font-weight: 200 !important;
}

.text_1 {
  margin-top: 0px;
  flex-direction: column;
  padding: 10px;

  .tatle_2 {
    font-size: 17px;
    font-weight: 700;
    color: #727171;
  }

  .date_1 {
    justify-content: space-between;
    margin-top: 35px;
    color: #c0c0c0;
    font-size: 16px;

    .iconRotate1 {
      display: inline-block;
      margin-right: 14px;
      color: #92ab26;
      transform: rotate(180deg);
      transform: rotateY(180deg);

      i {
        font-size: 20px;
        font-weight: 700;
      }
    }
  }
}
</style>
